<template>
  <div class="emb-product-detail bg-grey">
    <div>
      <template>
        <v-container>
          <v-row>
            <v-col>
              <v-banner v-if="customer_info != null" class="accent white--text">
                <div class="text-h6">
                  {{ $t("message.productsPage.customerTicket") }}
                </div>
                <div>
                  {{ $t("message.productsPage.customer") }}:
                  <b
                    >{{ customer_info.firstname }}
                    {{ customer_info.lastname }}
                  </b>
                </div>
                <div>
                  {{ $t("message.productsPage.customerId") }}:
                  <b>
                    {{ customer_id }}
                  </b>
                </div>
              </v-banner>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col
              ><v-img :src="lotteryInfo.image" max-width="200"> </v-img>
            </v-col>
            <v-col
              class="text-center align-center"
              v-if="
                general_info != null &&
                general_info.lotteries[lottery_name] != null &&
                general_info.lotteries[lottery_name].next_draw.amount != 0
              "
            >
              <h4
                v-html="
                  $formatMoneyToWord(
                    general_info.lotteries[lottery_name].next_draw.amount,
                    'US$',
                    'h2 style=\'display:inline;\''
                  )
                "
              ></h4>
              <emb-countdown
                :deadline="
                  $removeHours(
                    general_info.lotteries[
                      lottery_name
                    ].next_draw.date.toDate(),
                    general_info.limits.stop_purchase_hour
                  )
                "
              >
              </emb-countdown>
            </v-col>
          </v-row>
        </v-container>
        <promotion-stickers
          :type_name="lottery_name"
          :display_promotion_label="true"
          :logo_on_top="false"
          :display_price="true"
          v-if="(lottery_name != undefined || lottery_name != '') && can_buy"
        >
        </promotion-stickers>
        <div
          class="related-product bg-grey"
          v-if="
            (general_info != null &&
              can_buy &&
              general_info.lotteries[lottery_name].next_draw.amount != 0) ||
            customer_info != null
          "
        >
          <v-container grid-list-xl>
            <v-card v-if="panelIndex == undefined">
              <v-card-text class="primary white--text font-weight-bold">
                {{ $t("message.productsPage.selectionInfo") }}
              </v-card-text>
            </v-card>

            <v-expansion-panels v-model="panelIndex">
              <v-expansion-panel
                v-if="ticket.quickPicks != undefined"
                :class="panelIndex == 0 ? 'white' : 'grey lighten-3'"
                @change="qpGridSelection('quickPicks')"
              >
                <v-expansion-panel-header>
                  <div
                    :class="
                      panelIndex == 0
                        ? 'text-h6 black--text'
                        : 'text-h6 grey--text'
                    "
                  >
                    {{ $t("message.productsPage.sections.quickPicks.title") }}
                  </div>
                  <!-- <h5 :class="panelIndex == 0 ? 'black--text' : 'grey--text'">
                    {{ $t("message.productsPage.sections.quickPicks.title") }}
                  </h5> -->
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    {{ $t("message.productsPage.sections.quickPicks.desc") }}
                  </div>

                  <div class="d-flex flex-row align-center pa-2 ma-2">
                    <div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="accent"
                        @click="addRemoveQuickPicks(increment)"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </div>
                    <div class="mx-2 text-subtitle-1">
                      {{ ticket.quickPicks }}
                    </div>
                    <div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="accent"
                        @click="addRemoveQuickPicks(-increment)"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </div>
                    <div class="mx-2 text-subtitle-1">
                      {{
                        $t("message.productsPage.sections.quickPicks.product")
                      }}
                    </div>
                  </div>
                  <div v-if="customer_info != null">
                    <div class="d-flex flex-row pa-2">
                      <v-checkbox
                        v-model="single_ticket"
                        :label="$t('message.productsPage.singleTicket')"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="ticket.grids != undefined"
                :class="
                  panelIndex == getGridIndex() ? 'white' : 'grey lighten-3'
                "
                :disabled="grids_disabled"
                @change="qpGridSelection('grids')"
              >
                <v-expansion-panel-header>
                  <div
                    :class="
                      panelIndex == getGridIndex()
                        ? 'text-h6 black--text'
                        : 'text-h6 grey--text'
                    "
                  >
                    {{ $t("message.productsPage.sections.ownPicks.title") }}
                    <!-- </h5> -->

                    <div v-if="grids_disabled">
                      <br />
                      {{ $t("message.productsPage.gridsDisabled") }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="text-left">
                    <!-- <div class="pb-4">
                      {{ $t("message.productsPage.sections.ownPicks.info") }}
                    </div> -->
                    <div class="product-listing">
                      <v-layout row wrap mb-4>
                        <template v-for="(item, grid_index) in ticket.grids">
                          <v-flex
                            xs12
                            sm12
                            md6
                            lg4
                            xl3
                            mb3
                            text-center
                            :key="grid_index"
                          >
                            <emb-lotto-grid2
                              :ticket="ticket"
                              :gridIndex="grid_index"
                              :resetTrigger="reset_trigger"
                            ></emb-lotto-grid2>
                          </v-flex>
                        </template>
                      </v-layout>
                    </div>
                    <div
                      class="text-center"
                      v-if="ticket.grids.length < lotteryInfo.maxGrids"
                    >
                      <!-- <v-btn tile @click="addNewGrid()">
                        <v-icon left>add </v-icon>
                        {{ $t("message.productsPage.addGrid") }}
                      </v-btn> -->
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>

          <v-container grid-list-xl>
            <v-expansion-panels>
              <v-expansion-panel class="white">
                <v-expansion-panel-header disable-icon-rotate>
                  <div class="d-flex flex-wrap max-select-width">
                    <h6>{{ $t("message.productsPage.numberPlay") }}</h6>
                  </div>
                  <div
                    class="d-inline-flex flex-wrap pa-2"
                    style="max-width: 300px"
                  >
                    <v-select
                      :items="multiplayOptions"
                      item-text="text"
                      item-value="value"
                      v-model="ticket.multiplay"
                      solo
                      @click.native.stop
                    ></v-select>
                  </div>
                  <div></div>
                  <template v-slot:actions>
                    <v-icon> mdi-help-circle </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-3">
                    {{ $t("message.productsPage.multiPlayDesc") }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
              <v-expansion-panel class="white">
                <v-expansion-panel-header disable-icon-rotate>
                  <div class="d-flex flex-wrap max-select-width">
                    <v-checkbox
                      v-model="ticket.multiplier"
                      :label="
                        $t(
                          'message.productsPage.multiplier.' +
                            this.lottery_name +
                            '.name'
                        )
                      "
                      @click.native.stop
                      color="accent"
                    ></v-checkbox>
                  </div>
                  <div></div>
                  <template v-slot:actions>
                    <v-icon> mdi-help-circle </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-3">
                    {{
                      $t(
                        "message.productsPage.multiplier." +
                          this.lottery_name +
                          ".desc"
                      )
                    }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
          <v-container grid-list-xl>
            <div class="cart-shop-list">
              <div
                class="emb-card mb-12 pa-6 accent"
                v-if="getMode(panelIndex) != undefined"
              >
                <emb-price-description
                  :ticket="ticket"
                  :mode="getMode(panelIndex)"
                >
                </emb-price-description>
                <div v-if="isAdmin && customer_id != null && customer_private_info != null">
                  <template>
                    <v-container>
                      <div class="d-flex align-center">
                        <div class="text-h6 white--text px-2">
                          {{ $t("message.productsPage.soldPrice") }}:
                        </div>
                        <div class="px-2">
                          <v-text-field
                            label="Price"
                            v-model="price"
                            type="number"
                            dark
                            :rules="inputRules.positifNumberRules"
                          ></v-text-field>
                        </div>
                        <div class="px-2">
                          <v-radio-group dark v-model="selected_currency">
                            <v-radio label="USD" value="USD"></v-radio>
                            <v-radio label="CAD" value="CAD"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>

                      <div
                        class="d-flex align-center"
                        v-if="
                          customer_private_info != null &&
                          customer_private_info.credit > 0
                        "
                      >
                        <div class="text-h6 white--text px-2">
                          {{ $t("message.productsPage.useCredit") }}:
                        </div>
                        <div class="px-2">
                          <v-text-field
                            v-model="credit_amount"
                            type="number"
                            dark
                            :rules="inputRules.positifNumberRules"
                          ></v-text-field>
                        </div>
                        <div>
                          <v-btn x-small class="ma-2" @click="set_credit()">
                            <v-icon>mdi-arrow-left-bold-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="white--text">
                          ({{ $t("message.productsPage.availableCredit") }}:
                          {{
                            $formatMoney(
                              customer_private_info.credit,
                              2,
                              selected_currency
                            )
                          }})
                        </div>
                      </div>

                      <div
                        class="d-flex align-center"
                        v-if="
                          customer_private_info != null &&
                          customer_private_info.credit > 0
                        "
                      >
                        <div class="px-2 white--text">
                          Customer must pay:
                          {{
                            $formatMoney(
                              amount_to_pay() /
                                general_info.currency[selected_currency],
                              2,
                              selected_currency
                            )
                          }}
                        </div>
                      </div>
                    </v-container>
                  </template>
                </div>
                <div class="ma-5">
                  <v-layout align-center justify-center>
                    <v-flex>
                      <div class="text-center">
                        <div v-if="cart_index == -1 && customer_id == null">
                          <div>
                            <v-btn
                              tile
                              @click="addToCartOnly()"
                              class="primary white--text"
                            >
                              <v-icon left>shopping_cart </v-icon>
                              {{ $t("message.productsPage.addToCart") }}
                            </v-btn>
                          </div>
                          <div>
                            <v-btn
                              tile
                              @click="addToCartAndCheckout()"
                              class="ma-3 primary white--text"
                            >
                              <v-icon left>mdi-credit-card-outline </v-icon>
                              {{ $t("message.productsPage.addAndCheckout") }}
                            </v-btn>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            cart_index == -1 && customer_id != null && isAdmin
                          "
                        >
                          <v-btn
                            tile
                            @click="processOrder()"
                            class="ma-3"
                            :disabled="
                              price < 0 ||
                              is_processing ||
                              selected_currency == null
                            "
                          >
                            <div v-if="!is_processing">
                              <v-icon left>mdi-credit-card-outline </v-icon>
                              {{ $t("message.productsPage.processOrder") }}
                            </div>
                            <div v-else>
                              <v-progress-circular
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </v-btn>
                        </div>

                        <div v-else>
                          <v-btn tile @click="updateCart()" block>
                            {{ $t("message.productsPage.updateCart") }}
                          </v-btn>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
              <div v-else class="mb-12"></div>
            </div>
          </v-container>
        </div>
        <div
          v-else-if="
            general_info != null &&
            general_info.lotteries[lottery_name].next_draw.amount == 0
          "
        >
          <v-container grid-list-xl>
            <v-card>
              <v-card-text>
                {{ $t("message.banner.waitingResult") }}
              </v-card-text>
            </v-card>
          </v-container>
        </div>
        <div v-else class="pb-3">
          <v-container grid-list-xl>
            <v-card>
              <v-card-text> {{ $t("message.banner.tooLate") }} </v-card-text>
            </v-card>
          </v-container>
        </div>
      </template>
    </div>

    <emb-warning-dialog ref="warningDialog" warning_name="separate_ticket">
    </emb-warning-dialog>
    <v-snackbar v-model="error_snackbar" color="red" timeout="5000">
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text @click="error_snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.max-select-width {
  max-width: 150px;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
}

.text {
  color: black;
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}

.rotated-text {
  display: flex;
  justify-content: center;
}

.text_vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 18px; /* Adjust as needed */
}
</style>

<script>
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import PromotionStickers from "Components/Widgets/PromotionStickers.vue";
export default {
  components: { PromotionStickers },
  computed: {
    ...mapGetters([
      "getUserInfo",
      "getUserPrivateInfo",
      "cart",
      "cart_tickets",
      "currentTicket",
      "validate_ticket",
      "lotteries_info",
      "general_info",
      "get_new_ticket",
      "session_warning",
      "isAdmin",
    ]),
  },
  beforeMount() {
    if (this.general_info == null) {
      this.$store.dispatch("fetch_general_info").then(() => {
        this.$store.dispatch("fetch_pricing").then(() => {
          this.update_grids_disabled();
        });
      });
    }
    if (this.$route.params["cart_index"] != undefined) {
      this.cart_index = parseInt(this.$route.params["cart_index"]);
      if (this.cart_tickets.length != 0) {
        this.updateGridsFromCart();
      }
    } else {
      this.newTicket();
    }
  },
  watch: {
    "$store.state.cart_tickets": function () {
      this.updateGridsFromCart();
    },
    "$store.state.userInfo": function () {
      if (this.getUserInfo != null) {
        this.userInfo = this.getUserInfo;
        this.admin_init();
      }
    },
    $route() {
      if (this.$route.params["cart_index"] != undefined) {
        this.cart_index = parseInt(this.$route.params["cart_index"]);
        if (this.cart_tickets.length != 0) {
          this.updateGridsFromCart();
        }
      } else {
        this.newTicket();
      }
      this.reset_trigger += 1;

      this.page_init();
    },
  },
  data() {
    return {
      can_buy: true,
      customer_id: null,
      customer_info: null,
      customer_private_info: null,
      increment: 3,
      lottery_name: "",
      lotteryInfo: {},
      panelIndex: 0,
      mode: -1,
      type: 1,
      multiplayOptions: [],
      gridRef: [],
      cart_index: -1,
      ticket: {},
      reset_trigger: 0,
      userInfo: null,
      price: -1,
      promoted_products: [],
      is_processing: false,
      grids_disabled: false,
      last_qp_multiplay: -1,
      selected_currency: null,
      single_ticket: false,
      inputRules: {
        positifNumberRules: [(v) => v >= 0 || "The amount must be 0 or more."],
      },
      credit_amount: 0,
      error_snackbar: false,
      error_message: "",
    };
  },
  mounted() {
    this.admin_init();
    this.userInfo = this.getUserInfo;
    this.page_init();
  },
  methods: {
    page_init() {
      this.$store
        .dispatch("can_buy", { type: this.lottery_name, root: this.$root })
        .then((can_buy) => {
          this.can_buy = can_buy;
        });

      this.$store
        .dispatch("get_promoted_products", {
          type_name: this.$route.params["name"],
        })
        .then((promoted_products) => {
          this.promoted_products = promoted_products;
        });
    },
    admin_init() {
      if (this.getUserInfo != null) {
        if (this.isAdmin && this.$route.query["customer_id"] != undefined) {
          this.customer_id = this.$route.query.customer_id;
          getDoc(doc(getFirestore(), "users_private", this.customer_id)).then(
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                this.customer_private_info = docSnapshot.data();
              }
            }
          );

          this.$store
            .dispatch("get_user_info_cached", this.customer_id)
            .then((user_info) => {
              this.customer_info = user_info;
              this.increment = 1;
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        }
      }
    },
    newTicket() {
      this.lottery_name = this.$route.params["name"];
      this.ticket = this.get_new_ticket(this.lottery_name);

      this.resetLotteryInfo(this.$route.params["name"]);
      this.panelIndex = 0;
      this.cart_index = -1;
      this.reset_trigger += 1;
      this.update_grids_disabled();
    },
    updateGridsFromCart() {
      if (this.cart_tickets.length > 0 && this.cart_index != -1) {
        this.ticket = this.cart_tickets[this.cart_index].ticket;
        var ticket_type = "quickPicks";
        if (this.ticket.grids.length > 0) {
          ticket_type = "grids";
        }
        this.lottery_name = this.ticket.type_name;

        this.resetLotteryInfo(this.ticket.type_name, ticket_type);

        this.panelIndex = 0;
      }
    },
    // addNewGrid() {
    //   if (this.ticket.grids.length < this.lotteryInfo.maxGrids) {
    //     this.ticket.grids.push({
    //       pool: [{ numbers: [] }, { numbers: [] }],
    //     });
    //   }

    //   if (
    //     this.ticket.grids.length > this.lotteryInfo.maxPerTicket &&
    //     !this.session_warning.separate_ticket.status
    //   ) {
    //     this.$refs.warningDialog.openDialog();
    //   }
    // },
    // deleteGrid(index) {
    //   var gridLength = this.ticket.grids.length;
    //   if (gridLength > this.lotteryInfo.minGrids) {
    //     this.ticket.grids.splice(index, 1);
    //   }
    // },
    addToCart() {
      var ticketAdded = false;
      var validated_tickets = this.validate_ticket(this.ticket);
      if (
        this.getMode(this.panelIndex) == 1 &&
        validated_tickets >= this.ticket.grids.length
      ) {
        ticketAdded = true;
        this.$store.dispatch("add_to_cart", {
          ticket: this.ticket,
          type: "grids",
        });
      } else if (this.getMode(this.panelIndex) == 1) {
        this.$snotify.error(
          this.$t("message.productsPage.error.missing_numbers"),
          {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 1000,
            showProgressBar: false,
          }
        );
        return false;
      }

      if (this.panelIndex == 0 && this.ticket.quickPicks > 0) {
        ticketAdded = true;
        this.$store.dispatch("add_to_cart", {
          ticket: this.ticket,
          type: "quickpicks",
        });
      }

      if (!ticketAdded) {
        this.$snotify.error(
          this.$t("message.productsPage.error.nothing_to_add"),
          {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 1000,
            showProgressBar: false,
          }
        );
        return false;
      }
      return true;
    },
    addToCartOnly() {
      if (this.userInfo == null) {
        this.$router.push("/session/signin");
        return;
      }
      if (this.addToCart()) {
        this.newTicket();
        window.scrollTo(0, 0);
        this.$snotify.success(this.$t("message.productsPage.productAdded"), {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000,
        });
      }
    },
    addToCartAndCheckout() {
      if (this.userInfo == null) {
        this.$router.push("/session/signin");
        return;
      }
      if (this.addToCart()) {
        this.$router.push("/checkout/payment");
      }
    },
    updateCart() {
      if (
        this.panelIndex == 0 ||
        this.validate_ticket(this.ticket) == this.ticket.grids.length
      ) {
        this.$store.dispatch("update_cart");
        this.$router.back();
      } else {
        this.$snotify.error(
          this.$t("message.productsPage.error.missing_numbers"),
          {
            closeOnClick: false,
            pauseOnHover: false,
            timeout: 1000,
            showProgressBar: false,
          }
        );
      }
    },
    addRemoveQuickPicks(value) {
      this.ticket.quickPicks += value;

      if (
        this.ticket.quickPicks > this.lotteryInfo.maxPerTicket &&
        !this.session_warning.separate_ticket.status &&
        this.customer_info == null
      ) {
        this.$refs.warningDialog.openDialog();
      }

      if (this.ticket.quickPicks < this.lotteryInfo.minQuickPicks) {
        this.ticket.quickPicks = this.lotteryInfo.minQuickPicks;
      } else if (this.ticket.quickPicks > this.lotteryInfo.maxQuickPicks) {
        this.ticket.quickPicks = this.lotteryInfo.maxQuickPicks;
      }
    },
    resetLotteryInfo(lotterie_type_name, type) {
      this.$store.dispatch("fetch_pricing").then((pricing_info) => {
        this.lotteryInfo = pricing_info[lotterie_type_name];
        this.multiplayOptions = [];
        for (var multiplay_count in pricing_info[lotterie_type_name].multiplay) {
          // For grids, do not allowed single draw
          if (type == "grids" && multiplay_count == 1) {
            continue;
          }
          var multiplaySaveElement = pricing_info[lotterie_type_name].multiplay[multiplay_count];
          var textElement =
          multiplay_count == 1
              ? this.$t("message.productsPage.multiplaySingle")
              : this.$t("message.productsPage.multiplayMultiple", {
                  num: multiplay_count,
                  save: multiplaySaveElement,
                });
          
          this.multiplayOptions.push({
            text: textElement,
            value: multiplay_count,
          });
        }
        if (type == "grids" && this.ticket.multiplay == 1) {
          this.ticket.multiplay = this.multiplayOptions[0].value;
        } 

        this.ticket.multiplay = this.multiplayOptions[0].value;
      });
    },
    // The expension panel give different indexes if all the panel are shown or just on
    // Use these functions to get the real index
    getGridIndex() {
      if (this.ticket.quickPicks != undefined) {
        return 1;
      } else {
        return 0;
      }
    },
    getMode(panelIndex) {
      if (
        (this.cart_index != -1 && this.ticket.grids != undefined) ||
        (this.cart_index == -1 && panelIndex == 1)
      ) {
        return 1;
      } else if (
        this.cart_index != -1 ||
        (this.cart_index == -1 && panelIndex == 0)
      ) {
        return 0;
      }
    },
    processOrder() {
      if (this.isAdmin) {
        this.is_processing = true;
        if (this.panelIndex == 0 && this.ticket.quickPicks > 0) {
          delete this.ticket["grids"];
        } else {
          delete this.ticket["quickPicks"];
        }
        // Get price in US
        var price = parseFloat(this.price);

        var temp_cart = [{ ticket: this.ticket }];
        var credit_amount =
          Math.round(
            (this.credit_amount /
              this.general_info.currency[this.selected_currency]) *
              100
          ) / 100;

        if (
          credit_amount > 0 &&
          credit_amount > this.customer_private_info.credit
        ) {
          this.is_processing = false;
          this.error_snackbar = true;
          this.error_message = "Not enough credit";
          return;
        }

        const interacPaymentUserFunc = httpsCallable(
          getFunctions(),
          "createInteracPaymentForUser"
        );

        var payment_args = {
          cart: temp_cart,
          currency_rate: this.general_info.currency["CAD"],
          customer_id: this.customer_id,
          customer_info: this.customer_info,
          price: price / this.general_info.currency[this.selected_currency],
          use_credit: credit_amount > 0 ? true : false,
          credit_amount: credit_amount,
          single_ticket: this.single_ticket,
        };

        interacPaymentUserFunc(payment_args)
          .then(() => {
            this.is_processing = false;
            this.customer_id = null;
            this.$router.back();
            this.$snotify.success(
              "Order added to " +
                this.customer_info.firstname +
                " " +
                this.customer_info.lastname,
              {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 1000,
              }
            );
          })
          .catch((error) => {
            this.is_processing = false;
            console.log("Error: ", error);
          });
      }
    },
    update_grids_disabled() {
      if (
        this.general_info != null &&
        this.general_info.limits.grids_blocked != undefined &&
        this.general_info.limits.grids_blocked.includes(this.lottery_name)
      ) {
        this.grids_disabled = true;
      } else {
        this.grids_disabled = false;
      }
    },
    qpGridSelection(type) {
      this.resetLotteryInfo(this.ticket.type_name, type);
    },
    set_credit() {
      if (this.price > 0 && this.selected_currency != undefined) {
        var rate = this.general_info.currency[this.selected_currency];
        if (this.customer_private_info.credit > this.price) {
          this.credit_amount = Math.round(this.price * rate * 100) / 100;
        } else {
          this.credit_amount =
            Math.round(this.customer_private_info.credit * rate * 100) / 100;
        }
      }
    },
    amount_to_pay() {
      if (this.credit_amount > 0) {
        return this.price - this.credit_amount;
      } else {
        return this.price;
      }
    },
  },
};
</script>
