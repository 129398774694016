<template>
    <v-dialog v-model="open" max-width="550" @click:outside="close">
      <v-card class="py-6 px-2">
        <h6 class="text-center mb-6">{{ message }}</h6>
        <v-card-actions class="layout justify-center">
          <v-btn color="accent" @click="close">{{
            $t("message." + button_label)
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: ["message_desc", "button_label"],
    data() {
      return {
        open: false,
        message: "",
      };
    },
    mounted() {
      this.message = this.$t("message." + this.message_desc);
    },
    methods: {
      openDialog() {
        this.open = true;
      },
      close() {
        this.open = false;
      },
    },
  };
  </script>
  
  