/**
* VueShop Store
*/

import Vue from 'vue'
import Vuex from 'vuex'

// modules
import sidebar from './modules/sidebar';
import settings from './modules/settings';
import payment from './modules/payment';
import router from '../router/index'
import { getAuth, signOut, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, onSnapshot, query, where, orderBy, limit, getDocs, collection, setDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, put } from "firebase/storage";
import lotteriesInfoImport from "Assets/data/lotteriesInfo";
import { convert_currency, convert_ticket_to_code, compare_product_pattern } from "../services/coupon_utils";

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules: {
        sidebar,
        settings,
        payment,
    },
    state: {
        isAdmin: false,
        isPartner: false,
        lotteriesInfo: null,
        generalInfo: null,
        pricing: null,
        dummy: null,
        user: null,
        userEmailVerified: false,
        userInfo: null,
        userPrivateInfo: null,
        userAssignTask: null,
        simulate_user: null,
        simulate_user_info: null,
        simulate_user_private_info: null,
        freeLotteries: [],
        freeLotteriesListener: null,
        freeSubscriptions: {},
        freeSubscriptionsListener: null,
        imageOCRListener: null,
        orderListener: null,
        cartListener: null,
        userPrivateInfoListener: null,
        reports: {},
        currentTicket: {
            type_name: "",
            numbers: {},
            multiplier: false,
        },
        cart_tickets: [],
        cart_info: {},
        coupon_info: null,
        invoiceData: null,
        // orderCache: {},
        watchObject: {},
        userOrders: [],
        newNotifications: [],
        notifications: [],
        lastTab: {
            "orders": 0
        },
        use_credit: true,
        selected_payment_type_currency: "USD",
        session_warning: {
            separate_ticket: {
                message: "separate_ticket",
                status: false,
            }
        },
        admin_tables: {
            soldList: [],
            assignedList: [],
            completedList: [],
            canceledList: [],
            winningList: [],
        },
        user_info_cached: {},
        coupon_list: [],
        utm: null,
    },
    // getters
    getters: {
        utm: state => {
            return state.utm;
        },
        admin_tables: state => {
            return state.admin_tables;
        },
        getUser: state => {
            return state.simulate_user == null ? state.user : state.simulate_user;
        },
        isSimulating: state => {
            return state.simulate_user != null;
        },
        isAdmin: state => {
            return state.isAdmin;
        },
        isPartner: state => {
            return state.isPartner;
        },
        getUserInfo: state => {
            return state.simulate_user_info == null ? state.userInfo : state.simulate_user_info;
        },
        getUserPrivateInfo: state => {
            return state.simulate_user_private_info == null ? state.userPrivateInfo : state.simulate_user_private_info;
        },
        getUserInfoAssignTask: state => {
            return state.userAssignTask;
        },
        getUserOrders: state => {
            return state.userOrders;
        },
        getUserNewNotifications: state => {
            return state.newNotifications;
        },
        getUserNotifications: state => {
            return state.notifications;
        },
        getDisplayNameFromUid: state => (uid) => {
            if (state.userAssignTask == null) {
                return uid;
            }
            for (var element of state.userAssignTask) {
                if (element.uid == uid) {
                    return element.display_name;
                }
            }
            return uid;
        },
        freeLotteries: state => {
            return state.freeLotteries;
        },
        freeLotteriesSubscriptions: state => {
            return state.freeSubscriptions;
        },
        myTickets: state => {
            return state.myTickets;
        },
        invoice: state => {
            return state.invoiceData;
        },
        currentTicket: state => {
            return state.currentTicket;
        },
        cart_tickets: state => {
            return state.cart_tickets;
        },
        cart_info: state => {
            return state.cart_info;
        },
        coupon_info: state => {
            return state.coupon_info;
        },
        pricing_info: state => {
            return state.pricing;
        },
        session_warning: state => {
            return state.session_warning;
        },
        use_credit: state => {
            return state.use_credit;
        },
        selected_payment_type_currency: state => {
            return state.selected_payment_type_currency;
        },
        lotteries_info: state => {
            if (state.lotteriesInfo == null) {
                state.lotteriesInfo = lotteriesInfoImport;
            }
            return state.lotteriesInfo;
        },
        general_info: state => {
            return state.generalInfo;
        },
        getPrice: (state, getters) => (ticket_name, price_type) => {
            var lotteryInfo = getters.lotteries_info.lotteries[ticket_name];
            if (lotteryInfo == undefined) {
                return 0;
            } else {
                return lotteryInfo.price[price_type].basePrice + lotteryInfo.price[price_type].commission;
            }
        },
        getRawPrice: (state, getters) => (ticket_name, price_type) => {
            var pricing = getters.pricing_info;
            if (pricing == null) {
                return 0;
            }

            var lotteryInfo = pricing[ticket_name];
            if (lotteryInfo == undefined) {
                return 0;
            } else {
                return lotteryInfo.base_price[price_type];
            }
        },
        getFee: (state, getters) => (ticket_name, price_type) => {
            var pricing = getters.pricing_info;
            if (pricing == null) {
                return 0;
            }

            var lotteryInfo = pricing[ticket_name];
            if (lotteryInfo == undefined) {
                return 0;
            } else {
                return lotteryInfo.service_fee[price_type];
            }
        },
        // info = {ticket, mode}
        getTicketSubTotalPrice: (state, getters) => (info) => {
            if (getters.pricing_info == null) {
                return 0;
            }

            var pourcentage = 1;
            var product_count = 1;
            if (info.coupon != undefined || info.coupon != null) {
                // Check if the ticket is valid for the coupon
                var valid_product = false;
                const product_code = convert_ticket_to_code(info.ticket);
                for (var pattern of info.coupon.products) {
                    var pattern_result = compare_product_pattern(pattern, product_code);
                    if (pattern_result[0]) {
                        valid_product = true;
                        product_count = pattern_result[1];
                    }
                }
                if (valid_product) {
                    if (info.coupon.type == "pourcentage") {
                        pourcentage = 1 - (info.coupon.discount / 100);
                    } else if (info.coupon.type == "fixed_price") {
                        return convert_currency(getters.general_info, info.coupon.currency, "USD", info.coupon.price) * product_count;
                    }
                }
            }
            var grid_price = getters.getRawPrice(info.ticket.type_name, "grids");
            var quick_picks_price = getters.getRawPrice(info.ticket.type_name, "quickpicks");
            var multiplier_price = getters.getRawPrice(info.ticket.type_name, "multiplier");

            var grid_fee = getters.getFee(info.ticket.type_name, "grids");
            var quick_picks_fee = getters.getFee(info.ticket.type_name, "quickpicks");
            var multiplier_fee = getters.getFee(info.ticket.type_name, "multiplier");

            var raw_total = 0;
            var fee_total = 0;

            if (info.ticket.grids != undefined && (info.mode == undefined || info.mode == 1)) {
                raw_total = info.ticket.grids.length * grid_price; // Grids
                fee_total = info.ticket.grids.length * grid_fee;

                // Multiplier
                if (info.ticket.multiplier) {
                    raw_total += info.ticket.grids.length * multiplier_price;
                    fee_total += info.ticket.grids.length * multiplier_fee;
                }
                raw_total *= info.ticket.multiplay; // Multiplay
                fee_total *= info.ticket.multiplay;
            }

            if (info.ticket.quickPicks != undefined && (info.mode == undefined || info.mode == 0)) {
                raw_total = info.ticket.quickPicks * quick_picks_price;
                fee_total = info.ticket.quickPicks * quick_picks_fee;
                if (info.ticket.multiplier) {
                    raw_total += info.ticket.quickPicks * multiplier_price;
                    fee_total += info.ticket.quickPicks * multiplier_fee;
                }
                raw_total *= info.ticket.multiplay;
                fee_total *= info.ticket.multiplay;
            }

            // Calculate discount on multiplay
            var discount = 0;
            if (info.with_discount != undefined && info.with_discount) {
                // var indexOfDiscount = getters.lotteries_info.lotteries[
                //     info.ticket.type_name
                // ].multiplay.indexOf(info.ticket.multiplay);
                var discountPourcentage = getters.pricing_info[info.ticket.type_name].multiplay[info.ticket.multiplay];
                discount = fee_total * (discountPourcentage / 100);
            }

            if (info.fee_only != undefined && info.fee_only) {
                return fee_total * pourcentage;
            } else {
                return ((fee_total - discount) * pourcentage) + raw_total;
            }
        },

        // info = {ticket, mode}
        getTicketSubTotalRawPrice: (state, getters) => (info) => {
            if (getters.pricing_info == null) {
                return 0;
            }

            var grid_price = getters.getRawPrice(info.ticket.type_name, "grids");
            var quick_picks_price = getters.getRawPrice(info.ticket.type_name, "quickpicks");
            var multiplier_price = getters.getRawPrice(info.ticket.type_name, "multiplier");
            var grids_total_price = 0;

            if (info.ticket.grids != undefined && (info.mode == undefined || info.mode == 1)) {
                grids_total_price = info.ticket.grids.length * grid_price; // Grids
                if (info.ticket.multiplier) {
                    grids_total_price += info.ticket.grids.length * multiplier_price; // Multiplier
                }
                grids_total_price *= info.ticket.multiplay; // Multiplay
            }
            var quick_picks_total_price = 0;
            if (info.ticket.quickPicks != undefined && (info.mode == undefined || info.mode == 0)) {
                quick_picks_total_price = info.ticket.quickPicks * quick_picks_price;
                if (info.ticket.multiplier) {
                    quick_picks_total_price += info.ticket.quickPicks * multiplier_price;
                }
                quick_picks_total_price *= info.ticket.multiplay;
            }

            return ((grids_total_price + quick_picks_total_price))
        },
        // info = {ticket, mode}
        getTicketSubTotalProfitPrice: (state, getters) => (info) => {
            var pourcentage = 1;
            var product_count = 1;
            var fixed_price = 0;
            if (info.coupon != undefined && info.coupon != null) {
                // Check if the ticket is valid for the coupon
                var valid_product = false;
                const product_code = convert_ticket_to_code(info.ticket);
                for (var pattern of info.coupon.products) {
                    var pattern_result = compare_product_pattern(pattern, product_code);
                    if (pattern_result[0]) {
                        valid_product = true;
                        product_count = pattern_result[1];
                    }
                }
                if (valid_product) {
                    if (info.coupon.type == "pourcentage") {
                        pourcentage = 1 - (info.coupon.discount / 100);
                    } else if (info.coupon.type == "fixed_price") {
                        fixed_price = convert_currency(getters.general_info, info.coupon.currency, "USD", info.coupon.price) * product_count;
                        return fixed_price - getters.getTicketSubTotalRawPrice(info);
                    }
                }
            }
            var grid_price = getters.getFee(info.ticket.type_name, "grids");
            var quick_picks_price = getters.getFee(info.ticket.type_name, "quickpicks");
            var multiplier_price = getters.getFee(info.ticket.type_name, "multiplier");
            var grids_total_price = 0;

            if (info.ticket.grids != undefined && (info.mode == undefined || info.mode == 1)) {
                grids_total_price = info.ticket.grids.length * grid_price; // Grids
                if (info.ticket.multiplier) {
                    grids_total_price += info.ticket.grids.length * multiplier_price; // Multiplier
                }
                grids_total_price *= info.ticket.multiplay; // Multiplay
            }
            var quick_picks_total_price = 0;
            if (info.ticket.quickPicks != undefined && (info.mode == undefined || info.mode == 0)) {
                quick_picks_total_price = info.ticket.quickPicks * quick_picks_price;
                if (info.ticket.multiplier) {
                    quick_picks_total_price += info.ticket.quickPicks * multiplier_price;
                }
                quick_picks_total_price *= info.ticket.multiplay;
            }

            // Calculate discount
            // var indexOfDiscount = getters.lotteries_info.lotteries[
            //     info.ticket.type_name
            // ].multiplay.indexOf(info.ticket.multiplay);
            // var discrountPourcentage = getters.lotteries_info.lotteries[info.ticket.type_name].multiplaySave[
            //     indexOfDiscount];
            var discountPourcentage = getters.pricing_info[info.ticket.type_name].multiplay[info.ticket.multiplay];
            var discount = (grids_total_price + quick_picks_total_price) * (discountPourcentage / 100);

            return ((grids_total_price + quick_picks_total_price) - discount) * pourcentage;

        },
        getCartSubTotalPrice: (state, getters) => (cart_order, coupon) => {
            var total = 0;
            for (var product of cart_order) {
                total += getters.getTicketSubTotalRawPrice({ ticket: product.ticket }) + getters.getTicketSubTotalProfitPrice({ ticket: product.ticket, coupon: coupon });
            }
            return total;
        },

        getCartTotalPriceWithCredit: (state, getters) => (cart_order, coupon) => {
            var total = getters.getCartTotalPrice(cart_order, coupon);
            if (state.use_credit) {
                if (state.userPrivateInfo.credit >= total) {
                    return 0;
                } else {
                    return total - state.userPrivateInfo.credit;
                }
            }
            return total;
        },
        // getCartTotalRebate: (state, getters) => (cart_order, coupon) => {
        //     var rebate = 0;
        //     if ("coupon" in cart_order) {
        //         for (var product of cart_order) {
        //             rebate += getters.getTicketSubTotalProfitPrice({ ticket: product.ticket, coupon: coupon }) * cart_order.coupon.rebate / 100;
        //         }

        //     }
        //     return rebate;
        // },
        getCartTotalPrice: (state, getters) => (cart_order, coupon) => {
            return getters.getCartSubTotalPrice(cart_order, coupon);// - getters.getCartTotalRebate(cart_order, coupon);
        },
        getLastTab: (state) => (tab) => {
            return state.lastTab[tab];
        },
        // Return -1 if one of the grid miss selections
        // Return 0 if no grid have selection
        // Return > 0, the number of valid grid (no missing selection)
        validate_ticket: (state, getters) => (ticket) => {
            // console.log('validate_ticket ticket: ', ticket);
            // DEBUG THIS PART. Add to cart when all selected
            var lotterieInfo = getters.lotteries_info.lotteries[ticket.type_name];
            var valid_grid = 0;
            for (var grid_id in ticket.grids) {
                var grid = ticket.grids[grid_id];
                for (var i = 0; i < grid.pool.length; ++i) {
                    if (grid.pool[i].numbers.length != lotterieInfo.pools[i].count) {
                        return -1;
                    }

                }

                ++valid_grid;
            }
            return valid_grid;
        },

        get_new_ticket: (state, getters) => (type_name) => {
            var new_grids = [];

            for (var i = 0; i < getters.lotteries_info.lotteries[type_name].minGrids; i++) {
                new_grids.push({
                    pool: [
                        { numbers: [] },
                        { numbers: [] }
                    ],
                });
            }

            return {
                type_name: type_name,
                multiplier: false,
                quickPicks: getters.lotteries_info.lotteries[type_name].minQuickPicks,
                multiplay: 1,
                grids: new_grids
            }
        },

    },
    mutations: {
        setUtm(state, utm) {
            state.utm = utm;
        },
        setUser(state, user) {
            state.user = user;
        },
        setAdmin(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        setPartner(state, isPartner) {
            state.isPartner = isPartner;
        },
        setUserInfo(state, info) {
            state.userInfo = info;
        },
        setUserPrivateInfo(state, info) {
            state.userPrivateInfo = info;
        },
        setCartInfo(state, info) {
            state.cart_info = info;
        },
        setUserInfoAssignTask(state, list) {
            state.userAssignTask = list;
        },
        setFreeLotteries(state, lotteries) {
            state.freeLotteries = lotteries;
        },
        setLotteriesInfo(state, info) {
            state.lotteriesInfo = info;
        },
        setGeneralInfo(state, info) {
            state.generalInfo = info;
        },
        setCurrentTicket(state, ticket) {
            state.currentTicket = ticket;
        },
        setCouponInfo(state, info) {
            state.coupon_info = info;
        },
        setUseCredit(state, value) {
            state.use_credit = value;
        },
        // info = {tabIndex, page}
        setLastTab(state, info) {
            state.lastTab[info.page] = info.tabIndex;
        },
        set_selected_payment_type_currency(state, currency) {
            state.selected_payment_type_currency = currency;
        },
        stopListeningOrder(state) {
            if (state.orderListener != null) {
                state.orderListener();
                state.orderListener = null;
            }
        },
        fetchLocalStorage(state) {
            if (localStorage.cart) {
                state.cart_tickets = JSON.parse(localStorage.cart);
                if (Object.keys(state.cart_tickets).length === 0) {
                    state.cart_tickets = [];
                }
            }
        },
    },
    actions: {
        async logout({ state, commit }) {
            signOut(getAuth());
            commit('setUser', null);
            if (state.freeSubscriptionsListener != null) {
                state.freeSubscriptionsListener();
                state.freeSubscriptionsListener = null;
            }

            if (state.userPrivateInfoListener != null) {
                state.userPrivateInfoListener();
                state.userPrivateInfoListener = null;
            }

            if (state.cartListener != null) {
                state.cartListener();
                state.cartListener = null;
            }
            state.freeSubscriptions = [];
            state.userInfo = null;
            state.userPrivateInfo = null;
            state.cart_tickets = [];
            router.replace("/home");
        },
        async fetch_user_info({ commit }, userid) {
            return new Promise(function (resolve, reject) {
                getDoc(doc(getFirestore(), "users", userid))
                    .then((docSnapshot) => {
                        if (docSnapshot.exists()) {
                            commit('setUserInfo', docSnapshot.data());
                            resolve(docSnapshot.data());
                        } else {
                            console.error("No such document!");
                            reject();
                        }
                    })
                    .catch((error) => {
                        console.error("Error getting document:", error);
                        reject(error);
                    });
            });
        },

        async save_user_info({ state, commit }, user_info) {
            commit('setUserInfo', user_info);
            return updateDoc(doc(getFirestore(), "users", state.user.uid), user_info)

        },

        async fetch_user_private_info({ state }, userid) {
            if (state.userPrivateInfoListener == null) {
                state.userPrivateInfoListener = onSnapshot(doc(getFirestore(), "users_private", userid), (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        state.userPrivateInfo = docSnapshot.data();
                        if (state.userPrivateInfo["partner"] !== undefined && state.userPrivateInfo["partner"]) {
                            state.isPartner = true;
                        } else {
                            state.isPartner = false;
                        }
                    } else {
                        console.log("No such document!");
                    }
                }, (error) => {
                    console.log("Error getting document:", error);
                });
            }
        },

        async fetch_user_new_notifications({ state }, userid) {
            return new Promise(function (resolve, reject) {
                var notifications = [];
                const q = query(
                    collection(getFirestore(), "notifications"),
                    where("user_id", "==", userid),
                    where("read", "==", false),
                    orderBy("date", "desc")
                );

                getDocs(q)
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            notifications.push(doc.data());
                        });
                        state.newNotifications = notifications;
                        resolve();
                    })
                    .catch((error) => {
                        console.error("Error retrieving new notifications: ", error);
                        reject(error);
                    });
            });
        },
        async fetch_user_notifications({ state }, userid) {
            return new Promise(function (resolve, reject) {
                var notifications = [];
                const q = query(
                    collection(getFirestore(), "notifications"),
                    where("user_id", "==", userid),
                    orderBy("date", "desc"),
                    limit(15)
                );

                getDocs(q)
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            notifications.push(doc.data());
                        });
                        state.notifications = notifications;
                        resolve()
                    })
                    .catch((error) => {
                        console.error("Error retrieving notifications: ", error);
                        reject(error);
                    });
            });
        },
        async fetch_cart_info({ state }, user_id) {
            if (state.cartListener == null) {
                const cartRef = doc(getFirestore(), "cart", user_id);

                state.cartListener = onSnapshot(cartRef, (document) => {
                    const data = document.data();
                    let cart = [...state.cart_tickets];
                    if (document.exists() && "products" in data) {
                        cart = data.products;
                    }
                    state.cart_tickets = cart;
                });
            }
        },
        async forgot_password({ getters, commit }, email) {
            commit('setUser', null);
            getAuth().languageCode = getters.selectedLocale.locale;
            return sendPasswordResetEmail(getAuth(), email);
        },

        async fetch_free_lotteries({ commit, state }) {
            if (state.freeLotteriesListener == null) {
                const freeLotteriesQuery = query(
                    collection(getFirestore(), "free_tickets"),
                    where("draw_date", ">=", new Date())
                );

                state.freeLotteriesListener = onSnapshot(freeLotteriesQuery, (snapshot) => {
                    const documents = snapshot.docs.map((doc) => doc.data());
                    commit('setFreeLotteries', documents);
                });
            }

        },
        async fetch_general_info({ state }) {
            return new Promise(function (resolve) {
                if (state.generalInfo == null) {
                    const docRef = doc(getFirestore(), "info", "general");

                    return getDoc(docRef).then((documentSnapshot) => {
                        state.generalInfo = documentSnapshot.data();
                        resolve(state.generalInfo);
                    });
                } else {
                    resolve(state.generalInfo);
                }
            });
        },
        async fetch_pricing({ state }) {
            return new Promise(function (resolve) {
                if (state.pricing == null) {
                    const docRef = doc(getFirestore(), "info", "pricing");

                    return getDoc(docRef).then((documentSnapshot) => {
                        state.pricing = documentSnapshot.data();
                        resolve(state.pricing);
                    });
                } else {
                    resolve(state.pricing);
                }
            });
        },
        async fetch_coupon_list({ state }) {
            return new Promise(function (resolve) {
                if (state.coupon_list.length == 0) {
                    var coupons = [];
                    const q = query(
                        collection(getFirestore(), "coupons"),
                        where("valid", "==", true),
                        where("type", "==", "fixed_price")
                    );
                    getDocs(q)
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                coupons.push(doc.data());
                            });
                            state.coupon_list = coupons;
                            resolve(coupons);
                        })
                        .catch((error) => {
                            console.error("Error retrieving coupons: ", error);
                            resolve([]);
                        });
                } else {
                    resolve(state.coupon_list);
                }
            });
        },
        // Return the availble products for the valid coupons
        // options = {
        //     type_name: "powerball" or "megamillions",
        //}
        async get_promoted_products({ state, dispatch }, options) {
            state.dummy = null;
            var coupons = await dispatch('fetch_coupon_list');
            var promoted_products = [];
            for (var coupon of coupons) {
                if (coupon.exposed == true) {
                    for (var product of coupon.products) {
                        var prod_patterns = product.split("-");
                        if (prod_patterns[0] == "*" || (options.type_name != undefined && prod_patterns[0] == options.type_name)) {
                            var type_names = [];
                            if (prod_patterns[0] == "*" && options.type_name == undefined) {
                                type_names = ["powerball", "megamillions"];
                            } else {
                                type_names = [options.type_name];
                            }

                            for (var type_name of type_names) {
                                var multiplier = false;
                                if (prod_patterns[6] == "Y") {
                                    multiplier = true;
                                }
                                var product_info = {
                                    type_name: type_name,
                                    quickPicks: parseInt(prod_patterns[2]),
                                    multiplay: parseInt(prod_patterns[4]),
                                    multiplier: multiplier,
                                };
                                promoted_products.push({ product: product_info, coupon: coupon });
                            }
                        }
                    }
                }
            }
            return promoted_products;
        },
        get_user_info_cached({ state }, user_id) {
            return new Promise(function (resolve) {
                var user_info = state.user_info_cached[user_id];
                if (user_info == undefined) {
                    const docRef = doc(getFirestore(), "users", user_id);

                    return getDoc(docRef).then((documentSnapshot) => {
                        state.user_info_cached[user_id] = documentSnapshot.data();
                        resolve(documentSnapshot.data());
                    });
                } else {
                    resolve(user_info);
                }
            });
        },

        add_to_cart({ state }, ticket_info) {
            return new Promise(function (resolve) {
                if (ticket_info.type == "grids") {
                    if (ticket_info.ticket['quickPicks'] != undefined) {
                        delete ticket_info.ticket['quickPicks'];
                    }
                    state.cart_tickets.push({
                        ticket: ticket_info.ticket,
                    });
                } else if (ticket_info.type == "quickpicks") {
                    if (ticket_info.ticket.quickPicks > 0) {
                        delete ticket_info.ticket['grids'];
                        state.cart_tickets.push({
                            ticket: ticket_info.ticket,
                        });
                    }
                }

                if (state.user == null) {
                    localStorage.cart = JSON.stringify(state.cart_tickets);
                } else {
                    var transaction = {
                        'products': state.cart_tickets,
                    };
                    const docRef = doc(getFirestore(), "cart", state.user.uid);
                    setDoc(docRef, transaction);
                }
                resolve();
            });
        },
        update_cart({ state }) {
            if (state.user == null) {
                localStorage.cart = JSON.stringify(state.cart_tickets);
            } else {
                var transaction = {
                    'products': state.cart_tickets,
                };
                const docRef = doc(getFirestore(), "cart", state.user.uid);
                setDoc(docRef, transaction);
            }
        },
        remove_from_cart({ state }, index) {
            state.cart_tickets.splice(index, 1);

            if (state.user == null) {
                localStorage.cart = JSON.stringify(state.cart_tickets);
            } else {
                var transaction = {
                    'products': state.cart_tickets,
                };
                const docRef = doc(getFirestore(), "cart", state.user.uid);
                setDoc(docRef, transaction);
            }
        },
        empty_cart({ state }) {
            state.cart_tickets = [];

            localStorage.cart = JSON.stringify(state.cart_tickets);

            if (state.user != null) {
                var transaction = {
                    'products': [],
                };
                const docRef = doc(getFirestore(), "cart", state.user.uid);
                setDoc(docRef, transaction);
            }
        },
        // info : { update_values, docId }
        async update_order({ state }, info) {
            state.dummy = null;
            const docRef = doc(getFirestore(), "orders", info.docId);
            return updateDoc(docRef, info.update_values);
        },
        // info : { update_values, docId }
        async update_ticket({ state }, info) {
            state.dummy = null;
            const docRef = doc(getFirestore(), "users_tickets", info.docId);
            updateDoc(docRef, info.update_values);
        },
        async assign_order({ state }, order_change) {
            state.dummy = null;
            if (order_change.assigned_to != undefined && order_change.id != undefined) {
                var update_values = { assigned_to: order_change.assigned_to };
                if (order_change.status != undefined) {
                    update_values["status"] = order_change.status;
                }
                updateDoc(doc(getFirestore(), "orders", order_change.id), update_values);
            }
        },
        async update_ticket_checked({ state }, order) {
            state.dummy = null;
            var updated_values = { products: order.products };
            updateDoc(doc(getFirestore(), 'orders', order.id), updated_values);
        },
        // params: {image: blob, customMetadata: {} }
        async upload_image_to_storage({ state }, params) {
            state.dummy = null;
            const storage = getStorage();
            const storageRef = ref(storage, params.path);
            const uploadTask = uploadBytesResumable(storageRef, params.image, { customMetadata: params.customMetadata });

            uploadTask.then(() => {
                console.log("Image sent!");
            });
        },

        // params: {
        // order: order object
        // index: index of the product
        // }
        async get_order_image({ state }, params) {
            state.dummy = null;
            return new Promise(function (resolve) {
                if (params.order.products[params.index].scan != undefined) {
                    getDownloadURL(ref(getStorage(), params.order.products[params.index].scan.image))
                        .then((url) => {
                            resolve(url);
                        })
                        .catch((error) => {
                            console.log('Download error: ', error)
                            resolve("");
                        });
                } else {
                    resolve("");
                }
            });
        },
        async listen_order({ state }, order_id) {
            return new Promise(function (resolve) {
                if (state.orderListener != null) {
                    state.orderListener();
                }
                state.orderListener = onSnapshot(doc(getFirestore(), 'orders', order_id), (docSnapshot) => {
                    resolve(docSnapshot.data());
                });
            });
        },
        async fetch_user_orders({ state }) {
            return new Promise(function (resolve) {
                getDocs(collection(getFirestore(), 'orders')).then((querySnapshot) => {
                    var documents = [];
                    querySnapshot.forEach((doc) => {
                        documents.push(doc.data());
                        state.userOrders.push(doc.data());
                    });
                    state.userOrders = documents;
                });
                resolve(state.userOrders);
            });
        },
        async play_free_ticket({ state }, ticket) {
            var subscription = {
                user_id: state.user.uid,
                ticket_id: ticket.id,
                date: new Date(),
                draw_date: ticket.draw_date
            };
            return setDoc(doc(collection(getFirestore(), 'free_tickets_subscriptions')), subscription);
        },
        async fetch_free_subscriptions({ state }) {
            if (state.freeSubscriptionsListener == null && state.user != null) {
                state.freeSubscriptionsListener = onSnapshot(
                    query(
                        collection(getFirestore(), 'free_tickets_subscriptions'),
                        where('user_id', '==', state.user.uid),
                        where('draw_date', '>=', new Date())
                    ),
                    (snapshot) => {
                        const documents = {};
                        snapshot.forEach((doc) => {
                            documents[doc.data().ticket_id] = doc.data();
                        });
                        state.freeSubscriptions = documents;
                    }
                );
            }
        },
        async set_new_notifications_read({ state }) {
            var newStatus = { read: true };
            for (var index in state.newNotifications) {
                updateDoc(doc(getFirestore(), "notifications", state.newNotifications[index].id), newStatus);
            }
            state.newNotifications = [];
        },

        // info : {image: blob}
        // returns: id of the ai output document in /commands
        async send_image_to_ai({ state }, info) {
            return new Promise(function (resolve, reject) {
                console.log("info: ", info);
                var currentDate = new Date();
                const id = doc(collection(getFirestore(), "collection")).id;
                var metadata = {
                    customMetadata: {
                        user_id: state.user.uid,
                        id: id,
                    }
                };
                var storagePath;
                if (info.folder == "free_tickets") {
                    storagePath = info.folder + '/'
                        + currentDate.getFullYear().toString()
                        + '/'
                        + (currentDate.getMonth() + 1).toString()
                        + '/'
                        + id + '.jpg';
                } else {
                    storagePath = info.folder + '/'
                        + currentDate.getFullYear().toString()
                        + '/'
                        + (currentDate.getMonth() + 1).toString()
                        + '/'
                        + info.order.id + "_" + info.product_index.toString()
                        + '.jpg';
                }
                uploadBytesResumable(ref(getStorage(), storagePath), info.image, metadata)
                    .then((snapshot) => {
                        console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                        getDownloadURL(snapshot.ref)
                            .then((url) => {
                                console.log("Download url: ", url);
                                resolve({ id: id, image: url, path: snapshot.ref.fullPath });
                            });

                    }).catch((error) => {
                        reject(error);
                    })
            });
        },


        // info : {ticket: my_ticket, image: blob }
        async add_free_ticket({ state, getters }, info) {
            return new Promise(function (resolve, reject) {
                console.log("Ticket to add2: ", JSON.stringify(info.ticket));
                //console.log("Ticket to add: ", info.ticket);
                var cleanedNumbers = info.ticket.numbers[0]
                    .replace(/ /g, "")
                    .replace(/\(/g, "-")
                    .replace(/\)/g, "")
                    .trim();

                var numbersArray = cleanedNumbers.split("-");
                var lottoInfo = getters.lotteries_info.lotteries[info.ticket.type_name];
                var numbersCount = 0;
                for (var i in lottoInfo.pools) {
                    numbersCount += lottoInfo.pools[i].count;
                }

                info.ticket["added_by"] = getters.getUser.uid;

                // Valid ticket
                if (numbersArray.length == numbersCount) {
                    var currentDate = new Date();
                    const id = doc(collection(getFirestore(), "collection")).id;

                    info.ticket.id = id;
                    info.ticket.creation_time = currentDate;

                    var metadata = {
                        customMetadata: {
                            user_id: state.user.uid,
                            id: id,
                        }
                    };
                    //info.image = new Blob(['Hello, world!'], { type: 'text/plain' });

                    console.log('image: ', info.image);
                    const storage = getStorage();
                    const storagePath = `free_tickets/${currentDate.getFullYear()}/${currentDate.getMonth()}/${id}.jpg`;
                    const storageRef = ref(storage, storagePath);

                    put(storageRef, info.image, "data_url", metadata)
                        .then((snapshot) => {
                            // fb.storage.ref().child('free_tickets/'
                            //     + currentDate.getFullYear().toString()
                            //     + '/'
                            //     + currentDate.getMonth().toString()
                            //     + '/'
                            //     + id
                            //     + '.jpg').put(info.image, metadata).then((snapshot) => {
                            console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                            // snapshot.ref.getDownloadURL().then((url) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    info.ticket.image = url;
                                    // fb.db.collection('free_tickets').doc(id).set(info.ticket);
                                    const ticketRef = doc(getFirestore(), "free_tickets", id);
                                    setDoc(ticketRef, info.ticket)
                                });
                        });
                    resolve();
                } else {
                    reject("Invalid numbers.");
                }

                return;
            });
        },
        // info : {ticket: my_ticket, image: blob }
        async add_ticket_image({ state }, info) {
            state.dummy = null;
            return new Promise(function (resolve, reject) {
                var metadata = {
                    customMetadata: {
                        orderId: info.order.id,
                        product_index: info.product_index,
                    }
                };

                // fb.storage.ref().child('user_tickets/'
                //     + info["order"]["client_id"]
                //     + '/'
                //     + info["order"]["id"] + "_" + info["product_index"]
                //     + '.jpg').put(info.image, metadata).then((snapshot) => {
                const storageRef = ref(getStorage(), "user_tickets/" + info["order"]["client_id"] + "/" + info["order"]["id"] + "_" + info["product_index"] + ".jpg");
                put(storageRef, info.image, metadata)
                    .then((snapshot) => {
                        console.log('User ticket uploaded ', snapshot.totalBytes, 'bytes.');
                        getDownloadURL(snapshot.ref)
                            .then((url) => {
                                info.ticket["image"] = url;
                                // fb.db.collection('orders').doc(info["order"]["id"]).set(info.order)
                                const orderRef = doc(getFirestore(), "orders", info["order"]["id"]);
                                setDoc(orderRef, info.order)
                                    .then(() => {
                                        resolve()
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });

                            });
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },
        async send_verification_email({ state, getters }) {
            return new Promise(function (resolve, reject) {
                if (state.user) {
                    getAuth().languageCode = getters.selectedLocale.locale;
                    sendEmailVerification(state.user).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    })
                }
            });
        },
        // Wait a command response on firestore
        // info:
        // command_id: command id 
        async wait_command({ state }, info) {
            return new Promise(function (resolve/*, reject*/) {
                const commandRef = doc(getFirestore(), "commands", info.command_id);
                state.imageOCRListener = onSnapshot(commandRef, (snapshot) => {
                    if (snapshot.data() != undefined) {
                        resolve(snapshot.data());
                        state.imageOCRListener();
                        state.imageOCRListener = null;
                    }

                });
            });
        },
        async delete_command({ state }, id) {
            return new Promise(function (resolve/*, reject*/) {
                const commandRef = doc(getFirestore(), "commands", id);
                deleteDoc(commandRef);
                if (state.imageOCRListener != null) {
                    state.imageOCRListener();
                }
                state.imageOCRListener = null;
                resolve();
            });
        },
        // Create the order for credit payment
        // async make_payment_stripe({ state }, orders) {
        //     // var id = fb.db.collection("orders").doc().id;
        //     // var order = {
        //     //     id: id,
        //     //     client_id: state.user.uid,
        //     //     client_firstname: state.userInfo.firstname,
        //     //     client_lastname: state.userInfo.lastname,
        //     //     client_email: state.userInfo.email,
        //     //     creation_date: new Date(),
        //     //     status: "STRIPE",
        //     //     cart: state.cart_tickets,
        //     // }
        //     return new Promise(function (resolve) {

        //         // const now = new Date();
        //         // const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
        //         // const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000) - 1576800000

        //         var id = fb.db.collection("orders").doc().id;

        //         // var currency_title = "USD";
        //         // var currency_rate = getters.general_info.currency[currency_title];
        //         // var totalPrice = getters.getCartTotalPrice(orders);
        //         // var credit = 0;
        //         // if (state.use_credit) {
        //         //     if (totalPrice - state.userPrivateInfo.credit > 0) {
        //         //         credit = state.userPrivateInfo.credit;
        //         //     } else {
        //         //         credit = state.userPrivateInfo.credit = totalPrice;
        //         //     }
        //         // }

        //         var db_orders = {
        //             id: id,
        //             order_date: fb.firebase.firestore.Timestamp.fromDate(new Date()),
        //             products: orders_utils.separate_orders(orders),
        //             status: "STRIPE_CHECKOUT",
        //             order_type: "STRIPE",
        //             // sub_total_amount: getters.getCartSubTotalPrice(orders),
        //             // total_amount: (totalPrice - credit),
        //             // total_amount_currency: {
        //             //     sub_total_amount: getters.getCartSubTotalPrice(orders) * currency_rate,
        //             //     amount: (totalPrice - credit) * currency_rate,
        //             //     amount_no_credit: totalPrice * currency_rate,
        //             //     // total_tax_amount: getters.getCartTaxPrice(orders) * currency_rate,
        //             //     credit: credit * currency_rate,
        //             //     currency: currency_title
        //             // },
        //             // total_tax_amount: getters.getCartTaxPrice(orders),
        //             // receipt_id: utcSecondsSinceEpoch.toString(),
        //             client_id: state.user.uid,
        //             // credit: credit,
        //             client_firstname: state.userInfo.firstname,
        //             client_lastname: state.userInfo.lastname,
        //             client_email: state.userInfo.email,
        //             stripe_customer_id: state.userInfo.stripe_customer_id
        //         };

        //         fb.db.collection('orders').doc(id).set(db_orders).then(() => {
        //             resolve(db_orders);
        //         });
        //     });
        // },
        // //         Array(1)
        // // 0:
        // // ticket: Object
        // // multiplay: 1
        // // multiplier: false
        // // quickPicks: 7
        // // type_name: "powerball"
        // async separate_orders(orders) {
        //     var results = [];
        //     for (var order of orders) {
        //         // Separate quickpicks
        //         if (order.ticket.quickPicks > 5) {
        //             // Add full tickets
        //             for (var i = 0; i < Math.floor(order.ticket.quickPicks / 5); ++i) {
        //                 results.push({
        //                     ticket: {
        //                         multiplay: order.ticket.multiplay,
        //                         multiplier: order.ticket.multiplier,
        //                         quickPicks: 5,
        //                         type_name: order.ticket.type_name,
        //                     }
        //                 });
        //             }
        //             // Add the rest
        //             if (order.ticket.quickPicks % 5 != 0) {
        //                 results.push({
        //                     ticket: {
        //                         multiplay: order.ticket.multiplay,
        //                         multiplier: order.ticket.multiplier,
        //                         quickPicks: order.ticket.quickPicks % 5,
        //                         type_name: order.ticket.type_name,
        //                     }
        //                 });
        //             }
        //         } else if (order.ticket.grids.length > 5) {
        //             var new_order = undefined;
        //             for (var grid in order.ticket.grids) {
        //                 if (new_order == undefined) {
        //                     new_order = {
        //                         ticket: {
        //                             multiplay: order.ticket.multiplay,
        //                             multiplier: order.ticket.multiplier,
        //                             grids: [],
        //                             type_name: order.ticket.type_name,
        //                         }
        //                     }
        //                 }
        //                 new_order.ticket.grids.push(grid);

        //                 if (new_order.ticket.grids.length == 5) {
        //                     results.push(new_order);
        //                     new_order = undefined;
        //                 }
        //             }
        //             if (new_order != undefined) {
        //                 results.push(new_order);
        //             }
        //         } else {
        //             results.push(order);
        //         }
        //     }
        //     console.log("separate_orders: ", results);
        //     return results;
        // },
        // async make_payment_interac({ state, getters, dispatch }, orders) {
        //     return new Promise(function (resolve, reject) {

        //         const now = new Date();
        //         const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
        //         const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000) - 1576800000

        //         var id = fb.db.collection("orders").doc().id;

        //         var currency_title = "CAD";
        //         var currency_rate = getters.general_info.currency[currency_title];
        //         var totalPrice = getters.getCartTotalPrice(orders);
        //         var credit = 0;
        //         if (state.use_credit) {
        //             if (totalPrice - state.userPrivateInfo.credit > 0) {
        //                 credit = state.userPrivateInfo.credit;
        //             } else {
        //                 credit = state.userPrivateInfo.credit = totalPrice;
        //             }
        //         }

        //         // dispatch.separate_orders(orders);
        //         // // console.log("Orders: ", test);
        //         // this.separate_orders(orders);
        //         var db_orders = {
        //             id: id,
        //             order_date: fb.firebase.firestore.Timestamp.fromDate(new Date()),
        //             products: orders_utils.separate_orders(orders),
        //             general_status: "IN_PROCESS",
        //             status: "SOLD",
        //             order_type: "INTERAC",
        //             sub_total_amount: getters.getCartSubTotalPrice(orders),
        //             total_amount: (totalPrice - credit),
        //             total_amount_currency: {
        //                 sub_total_amount: getters.getCartSubTotalPrice(orders) * currency_rate,
        //                 amount: (totalPrice - credit) * currency_rate,
        //                 amount_no_credit: totalPrice * currency_rate,
        //                 // total_tax_amount: getters.getCartTaxPrice(orders) * currency_rate,
        //                 credit: credit * currency_rate,
        //                 currency: currency_title
        //             },
        //             // total_tax_amount: getters.getCartTaxPrice(orders),
        //             receipt_id: utcSecondsSinceEpoch.toString(),
        //             client_id: state.user.uid,
        //             credit: credit,
        //             client_firstname: state.userInfo.firstname,
        //             client_lastname: state.userInfo.lastname,
        //             client_email: state.userInfo.email
        //         };

        //         fb.db.collection('orders').doc(id).set(db_orders).then(() => {
        //             var info = { amount: totalPrice, payment_type: "INTERAC" };

        //             // Get order stats
        //             var quickPicksCount = 0;
        //             var gridsCount = 0;
        //             var powerBallCount = 0;
        //             var megamillionsCount = 0;
        //             var multiplierCount = 0;
        //             var multiplayCount = 0;
        //             for (var product_index in db_orders.products) {
        //                 var product = db_orders.products[product_index];
        //                 if (product.ticket.quickPicks != undefined && product.ticket.quickPicks > 0) {
        //                     quickPicksCount += product.ticket.quickPicks;
        //                     info["quick_picks"] = quickPicksCount;
        //                 }
        //                 if (product.ticket.grids != undefined && product.ticket.grids.length > 0) {
        //                     gridsCount += product.ticket.grids.length;
        //                     info["grids"] = gridsCount;
        //                 }
        //                 if (product.ticket.multiplay > 1) {
        //                     multiplayCount++;
        //                     info["multiplay"] = multiplayCount;
        //                 }
        //                 if (product.ticket.multiplier) {
        //                     multiplierCount++;
        //                     info["multiplier"] = multiplierCount;
        //                 }
        //                 if (product.ticket.type_name == "powerball") {
        //                     powerBallCount++;
        //                     info["powerball"] = powerBallCount;
        //                 }
        //                 if (product.ticket.type_name == "megamillions") {
        //                     powerBallCount++;
        //                     info["megamillions"] = megamillionsCount;
        //                 }
        //             }
        //             if (db_orders.credit > 0) {
        //                 info["use_account_credit"] = db_orders.credit;
        //             }
        //             info["amount" + db_orders.total_amount_currency.currency] = db_orders.total_amount_currency.amount_no_credit;
        //             Vue.prototype.$gtag.event('add_order', info);

        //             dispatch("empty_cart");
        //             state.invoiceData = db_orders;
        //             resolve();
        //             router.push('/checkout/final-receipt')
        //             return;
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //         // state.user.getIdToken().then((token) => {
        //         //     console.log("token: ", token);
        //         //     fetch('https://us-central1-lotto-amigo.cloudfunctions.net/interac_test',
        //         //         {
        //         //             method: "POST",
        //         //             body: JSON.stringify({ token: token }),
        //         //             headers: {
        //         //                 "Content-Type": "application/json"
        //         //             }
        //         //         }
        //         //     ).then((response) => {
        //         //         console.log("Response: ", response.text())
        //         //     });
        //         //     resolve();
        //         // });

        //     });
        // },
        // info: {type: bool, root: $root object}
        async can_buy({ state, dispatch }, info) {
            state.dummy = null;
            return new Promise(function (resolve) {
                dispatch("fetch_general_info").then((general_info) => {
                    var time_left = info.root.$removeHours(
                        general_info.lotteries[info.type].next_draw.date.toDate(),
                        general_info.limits.stop_purchase_hour
                    );
                    var can_buy = true;
                    if (Date.now() > time_left) {
                        can_buy = false;
                    }

                    resolve(can_buy);
                });
            });
        },
        async simulate_user({ state }, user_id) {
            return new Promise(function (resolve) {
                const docRef = doc(getFirestore(), "users", user_id);

                return getDoc(docRef).then((documentSnapshot) => {
                    state.simulate_user = { uid: user_id };
                    state.simulate_user_info = documentSnapshot.data();

                    const docRef = doc(getFirestore(), "users_private", user_id);
                    return getDoc(docRef).then((documentSnapshotPrv) => {
                        state.simulate_user_private_info = documentSnapshotPrv.data();
                        resolve();
                    });
                });
            });

        },
        cancel_simulation({ state }) {
            state.simulate_user = null;
            state.simulate_user_info = null;
            state.simulate_user_private_info = null;
        },
    }
})
