<template>
  <div>
    <v-tabs v-model="tab" align-with-title centered grow>
      <v-tab>{{ $t("message.myTicketsPage.ticketsTab") }}</v-tab>
      <v-tab v-if="gift_visible"
        >{{ $t("message.myTicketsPage.giftTicketsTab") }}
        <v-icon class="mx-2">mdi-gift</v-icon></v-tab
      >
      <v-tab>{{ $t("message.myTicketsPage.ordersTab") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="emb-card pa-4">
          <emb-user-tickets ticket_type="bought"> </emb-user-tickets>
        </div>
      </v-tab-item>
      <v-tab-item v-if="gift_visible">
        <div class="emb-card pa-4">
          <emb-user-tickets ticket_type="gift"> </emb-user-tickets>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="emb-card pa-4">
          <emb-user-orders exclude="COMPLETED"> </emb-user-orders>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserTickets from "Views/UserAccount/UserTickets";
import UserOrders from "Views/UserAccount/OrderHistory";
import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  getDocs,
} from "firebase/firestore";

export default {
  components: {
    embUserTickets: UserTickets,
    embUserOrders: UserOrders,
  },
  computed: {
    ...mapGetters(["getUser", "myTickets"]),
  },
  data() {
    return {
      tab: null,
      gift_visible: false,
    };
  },
  mounted() {
    if (this.getUser != null) {
      this.gift_exist();
    }
    if (this.$route.query.tab == "orders") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  },
  watch: {
    "$store.state.user": function () {
      this.gift_exist();
    },
  },
  methods: {
    gift_exist() {
      const usersTicketsCollRef = collection(getFirestore(), "users_tickets");
      const q = query(
        usersTicketsCollRef,
        where("gift_email", "==", this.getUser.email),
        limit(1)
      );
      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          this.gift_visible = true;
        }
      });
    },
  },
};
</script>