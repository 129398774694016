<template>
  <div>
    <div v-if="mode == 'resetPassword'">
      <div class="emb-signIn-wrap section-gap">
        <div class="container py-0">
          <v-layout row wrap align-center justify-center>
            <v-flex sm12 md12 lg8 xl7>
              <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
                <v-flex sm6 md7 lg6 xl6 hidden-sm-and-down>
                  <div class="form-img sign-in-image"></div>
                </v-flex>
                <v-flex sm10 md5 lg5 xl6>
                  <div
                    class="emb-card sign-in-form form-margin d-block white pa-6"
                  >
                    <h4>{{ $t("message.actionPage.title") }}</h4>
                    <v-form ref="form" v-model="valid">
                      <v-text-field
                        v-model.trim="signupForm.password"
                        type="password"
                        :placeholder="$t('message.signinPage.password')"
                        :rules="passwordRules"
                      >
                      </v-text-field>
                      <v-text-field
                        class="mb-4"
                        type="password"
                        :placeholder="$t('message.signinPage.retypePassword')"
                        :rules="repasswordRules"
                        v-model.trim="signupForm.repassword"
                      >
                      </v-text-field>
                      <!-- 
                  </v-text-field>
                  <div class="layout align-center justify-space-between">
                    <v-checkbox
                      v-model="checkbox"
                      :label="$t('message.rememberMe')"
                    ></v-checkbox>
                    <router-link
                      to="/session/forgot-password"
                      class="text-lg-right"
                      >{{ $t("message.forgotPassword") }}</router-link
                    >
                  </div>
                  <v-btn class="accent mb-3 ma-0" large v-on:click="login()">
                    {{ $t("message.signIn") }}
                  </v-btn>
                  <p>
                    {{ $t("message.dontHaveAccount") }}
                    <router-link to="/session/signup" class="accent--text">{{
                      $t("message.clickHereCreateAccount")
                    }}</router-link>
                  </p> -->
                    </v-form>
                    <v-btn
                      class="accent mx-0 mb-4"
                      large
                      @click.stop.prevent="submit"
                    >
                      {{ $t("message.actionPage.submit") }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <v-snackbar v-model="snackbar" color="red">
          {{ errorMsg(errorCode) }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" icon @click="snackbar = false" v-bind="attrs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
    <div v-else-if="mode == 'verifyEmail'">
      <div class="emb-signIn-wrap section-gap">
        <div class="container py-0">
          <v-layout row wrap align-center justify-center>
            <v-flex sm12 md12 lg8 xl7>
              <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
                <v-flex sm10 md5 lg5 xl6>
                  <div class="emb-card white pa-6 wrap">
                    <!-- <h5>{{ $t("message.actionPage.titleVerif") }}</h5> -->
                    <h5>{{ verificationMessage }}</h5>
                    <v-btn
                      class="accent mx-0 mb-4"
                      large
                      @click.stop.prevent="goToHome"
                    >
                      {{ $t("message.actionPage.home") }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import { getAuth, confirmPasswordReset, applyActionCode } from "firebase/auth";
export default {
  data() {
    return {
      verificationMessage: "",
      code: this.$route.query.oobCode,
      mode: this.$route.query.mode,
      errorCode: "",
      snackbar: false,
      valid: false,
      signupForm: {
        password: "",
        repassword: "",
      },
      passwordRules: [
        (v) => !!v || this.$t("message.signinPage.error.passwordRequired"),
        (v) =>
          v.length >= 6 ||
          this.$t("message.signinPage.error.auth/weak-password"),
        (v) =>
          (v && /^[A-Za-z\d@$!%*?&#()+=-_]{6,}$/.test(v)) ||
          this.$t("message.signinPage.error.auth/accepted-char"),
      ],
      repasswordRules: [
        (v) =>
          v == this.signupForm.password ||
          this.$t("message.signinPage.error.passwordDifferent"),
        (v) =>
          v.length >= 6 ||
          this.$t("message.signinPage.error.auth/weak-password"),
      ],
      inputRules: {
        basictextRules: [
          (v) => !!v || this.$t("message.signinPage.error.fieldNotEmpty"),
        ],
      },
    };
  },
  mounted() {
    if (
      this.mode == undefined ||
      (this.mode != "verifyEmail" && this.mode != "resetPassword")
    ) {
      console.log("Redirect to home, mode: ", this.mode);
      this.$router.replace("/home");
    } else if (this.mode == "verifyEmail") {
      console.log("Verifying email...");
      applyActionCode(getAuth(), this.code)
        .then(() => {
          this.verificationMessage = this.$t("message.actionPage.titleVerif");
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.verificationMessage = this.$t(
            "message.actionPage.error." + error.code
          );
        });
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.valid == true) {
        confirmPasswordReset(getAuth(), this.code, this.signupForm.password)
          .then(() => {
            this.$router.replace("/session/signin");
          })
          .catch((error) => {
            if (error.code != "") {
              this.snackbar = true;
              console.log("error: ", error);
              this.errorCode = error.code;
            }
          });
      }
    },
    goToHome() {
      this.$router.replace("/home");
    },
    errorMsg(code) {
      if (code != "") {
        return this.$t("message.actionPage.error." + code);
      } else {
        return "";
      }
    },
  },
};
</script>	
