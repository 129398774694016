export default [
    // {
    //     image_tr: "bannerCC",
    //     link: "",
    //     route: "",
    //     type: "image",
    // },
    {
        image_tr: "bannerPromo90",
        link: "",
        route: "",
        type: "promo",
    },
    {
        image_tr: "bannerNameTicket",
        link: "",
        route: "",
        type: "image",
    },
    // {
    //     image: "/static/images/slider-4-cc.jpg",
    //     subHeading: "message.banner.acceptCCDesc",
    //     heading: "message.banner.acceptCCTitle",
    //     link: "",
    //     route: "",
    //     type: "info_cc",
    // },
    {
        image: "/static/images/slider-0-about.jpg",
        subHeading: "message.banner.aboutDesc",
        heading: "message.banner.aboutTitle",
        link: "",
        route: "/about",
        type: "info",
    },
    {
        image: "/static/images/slider-1-pb.jpg",
        subHeading: "Big Sale",
        heading: "Women's Collection",
        link: "SHOP NOW",
        route: "/lotteries/powerball",
        type: "lottery",
        lottery_name: "powerball",
    },
    {
        image: "/static/images/slider-2-mm.jpg",
        subHeading: "",
        heading: "",
        link: "",
        route: "/lotteries/megamillions",
        type: "lottery",
        lottery_name: "megamillions",
    },
    {
        image: "/static/images/slider-3-free.jpg",
        subHeading: "message.banner.freeDesc",
        heading: "message.banner.freeTitle",
        link: "",
        route: "/freeLotteries",
        type: "freelottery"
    }
]