<template>
    <div class="countdown_header" v-if="activated" v-html="formattedTime">

    </div>
</template>

<style scoped>
.countdown_header {
    /* Layout and positioning */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; /* Adjust height as needed */
  width: 100%; /* Full width of the page */

  /* Visual styles */
  background-color: #F1D302; /* Background color */
  color: #000000; /* Text color */
  font-family: 'Arial', sans-serif; /* Font family */
  font-size: 1rem; /* Adjust text size */

  text-align: center;

  /* Box styles */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-bottom: 2px solid #444; /* Border to separate it visually */
}

</style>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
        timeRemaining: null, // Remaining time in milliseconds
        timer: null, // Reference to the interval timer
        //targetDateTime: new Date(Date.UTC(2024, 11, 23, 5, 0, 0)),
        timezoneOffset: new Date().getTimezoneOffset(),
        activated: false,
        general_info: null,
        timeover: false
        };
    },
    computed: {
        ...mapGetters([
        "selectedLocale",
        ]),
        formattedTime() {
            if (!this.timeRemaining || this.timeRemaining <= 0) return null;

            const seconds = Math.floor((this.timeRemaining / 1000) % 60);
            const minutes = Math.floor((this.timeRemaining / (1000 * 60)) % 60);
            const hours = Math.floor((this.timeRemaining / (1000 * 60 * 60)) % 24);
            const days = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));

            var countdown_time = this.$t("message.countdown", {days:days, hours:hours, minutes:minutes, seconds:seconds})
            return this.general_info.countdown.label[this.selectedLocale.locale].replace("%countdown", countdown_time);
        },
    },
    methods: {
        updateCountdown() {
            const now = new Date();
            const currentTimeWithOffset = new Date(now.getTime() + now.getTimezoneOffset() * 60000 + this.timezoneOffset * 60000); // Adjust current time for the specified timezone offset

            const targetTime = this.targetDateTime;
            const targetTimeWithOffset = new Date(targetTime.getTime() + targetTime.getTimezoneOffset() * 60000 + this.timezoneOffset * 60000); // Adjust target time for the specified timezone offset

            this.timeRemaining = targetTimeWithOffset - currentTimeWithOffset; // Calculate the difference in milliseconds

            if (this.timeRemaining <= 0) {
                clearInterval(this.timer);
                this.timeRemaining = 0;
            }
        },
    },
    mounted() {
        console.log(this.selectedLocale.locale)
        this.$store.dispatch("fetch_general_info").then((info) => {
            this.general_info = info;
            this.activated = info.countdown.activated;
            this.targetDateTime = info.countdown.date.toDate();
            this.updateCountdown(); // Initialize countdown
            this.timer = setInterval(this.updateCountdown, 1000); // Update every second
        });
    },    
    beforeDestroy() {
        if (this.timer) {
        clearInterval(this.timer); // Clear the interval when the component is destroyed
        }
    },    
    
};
</script>