<template>
  <div class="emb-signIn-wrap section-gap">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm10 md5 lg5 xl6>
              <div class="emb-card sign-in-form form-margin d-block white pa-6">
                <h4>{{ $t("message.signIn") }}</h4>
                <v-form>
                  <v-text-field
                    v-model.trim="loginForm.email"
                    type="email"
                    :placeholder="$t('message.email')"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model.trim="loginForm.password"
                    type="password"
                    :placeholder="$t('message.password')"
                  >
                  </v-text-field>
                  <div class="layout align-center justify-space-between">
                    <v-checkbox
                      v-model="checkbox"
                      :label="$t('message.rememberMe')"
                      color="accent"
                    ></v-checkbox>
                    <router-link
                      to="/session/forgot-password"
                      class="text-lg-right black--text"
                      >{{ $t("message.forgotPassword") }}</router-link
                    >
                  </div>
                  <div class="d-flex justify-center">
                    <v-btn class="accent mb-3 ma-0" large v-on:click="login()">
                      {{ $t("message.signIn") }}
                    </v-btn>
                  </div>
                </v-form>
                <div class="sign-up-box pa-3 mt-4">
                <div class="d-flex justify-center mb-2">
                  <div>
                      {{ $t("message.dontHaveAccount") }}
                      <router-link to="/session/signup" class="accent--text">{{
                        $t("message.clickHereCreateAccount")
                      }}</router-link>
                  </div>
              </div>
            <div class="d-flex justify-center">
            <v-btn small class="accent" @click="create_account()">
              {{ $t("message.signinPage.createAccount")  }}
            </v-btn>
                </div>
            <div>
              
            </div>
              </div>
              </div>

            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      {{ errorMsg(errorCode) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon @click="snackbar = false" v-bind="attrs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.sign-up-box {
  border: 2px solid #BF0719;
  background: #EEEEEE;
}
</style>
<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  data() {
    return {
      checkbox: false,
      loginForm: {
        email: "",
        password: "",
      },
      snackbar: false,
      errorCode: "",
    };
  },
  methods: {
    login() {
      signInWithEmailAndPassword(
        getAuth(),
        this.loginForm.email,
        this.loginForm.password
      ).catch((error) => {
        this.snackbar = true;
        this.errorCode = error.code;
      });
    },
    create_account() {
      this.$router.push("/session/signup");
    },
    errorMsg(code) {
      if (code != "") {
        return this.$t("message.signinPage.error." + code);
      } else {
        return "";
      }
    },
  },
};
</script>	
