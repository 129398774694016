export default {
	"home": "Home",
	"homeOne": "Home One",
	"homeTwo": "Home Two",
	"homeThree": "Home Three",
	"copyrightText": "© All Rights Reserved | Lotto Amigo website is operated by Black Danes LLC.",
	"close": "Close",
	"userProfile": "User Profile",
	"lotteries": "Lotteries",
	"subscriptionsMenu": {
		"label": "Subscriptions",
		"create": "Create Subscription",
		"my": "My Subscriptions",
	},
	"freeLotteries": "Free Lotteries",
	"myTickets": "My Tickets",
	"support": "Support",
	"powerball": "Powerball",
	"megamillions": "Mega Millions",
	"group": "Group",
	"groups": "Groups",
	"results": "Results",
	"shop": "Shop",
	"productDetails": "Product Details",
	"cart": "Cart",
	"checkout": "Checkout",
	"payment": "Payment",
	"fashion": "Fashion",
	"gadgets": "Gadgets",
	"accessories": "Accessories",
	"categories": "Categories",
	"men": "Men",
	"jean": "Jean",
	"jackets": "Jackets",
	"shirt": "Shirt",
	"tShirt": "T-Shirt",
	"women": "Women",
	"dress": "Dress",
	"dresses": "Dresses",
	"headphone": "Headphone",
	"smartphone": "Smartphone",
	"watch": "Watch",
	"speaker": "Speaker",
	"laptopAccessories": "Laptop Accessories",
	"belts": "Belts",
	"jewellery": "Jewellery",
	"purse": "Purse",
	"pages": "Pages",
	"about": "About",
	"aboutLottoAmigo": "About Lotto Amigo",
	"termAndCondition": "Term and Conditions",
	"privacyPolicy": "Privacy Policy",
	"privacyPolicySubTitle": "Your Information is fully secure with us.",
	"blogDetail": "Blog Detail",
	"faq": "FAQ",
	"Page": "Page",
	"404Page": '404 Page',
	"session": "Session",
	"signIn": "Sign In",
	"signOut": "Sign Out",
	"register": "Register",
	"email": "Email",
	"password": "Password",
	"rememberMe": "Remember Me",
	"forgotPassword": "Forgot Password",
	"dontHaveAccount": "Don't Have account?",
	"clickHereCreateAccount": "Click here to create one",
	"grid": "Grid",
	"grids": "Grids",
	"parts": "Parts",
	"select": "Select",
	"login": "Login",
	"signup": "Sign Up",
	"days": "days",
	"timesUp": "Time's Up!",
	"hi": "Hi",
	"play": "Play",
	"million": "Million",
	"billion": "Billion",
	"notAvailable": "Not available",
	"cancel": "Cancel",
	"yes": "Yes",
	"no": "No",
	"understand": "I understand",
	"interacEmail": "pay@serviceamigo.com",
	"maintenance": "We are closed for business due to maintenance. We are sorry for the inconvenience.<br><b><u>We will back back on Oct 20th.</u></b>",
	"newEmail": "The Interac email payment has changed. Please use the new email:",
	"copyToClipboard": "Copy to clipboard",
	"copied": "Copied!",
	"countdown": "{days}d {hours}h {minutes}m {seconds}s",
	"monthsShortNames": {
		"1": "Jan",
		"2": "Feb",
		"3": "Mar",
		"4": "Apr",
		"5": "May",
		"6": "Jun",
		"7": "Jul",
		"8": "Aug",
		"9": "Sep",
		"10": "Oct",
		"11": "Nov",
		"12": "Dec",
	},
	"language": {
		"en": "English",
		"fr": "French"
	},
	"languageShort": {
		"en": "EN",
		"fr": "FR"
	},
	"sideBar": {
		"english": "Anglais",
		"fench": "French",
		"customerInfo": "Customer Info",
		"settings": "Settings",
		"tools": "Tools",
	},
	"signinPage": {
		"title": "Enter Your Details",
		"firstname": "First Name*",
		"lastname": "Lastname*",
		"dob": "Date of Birth* (18+)",
		"email": "Email*",
		"phone": "Phone",
		"password": "Enter Password*",
		"retypePassword": "Retype Password*",
		"signup": "Sign Up",
		"haveAccount": "Already have account?",
		"createAccount": "Create Account",
		"signin": "Sign In",
		"seeConditions": "(See conditions)",
		"conditions": "Conditions",
		"acceptConditions": "I accept the conditions",
		"newletters": "Subscribe to newletters to receive information about new features, promotions and upcoming draws.",
		"sms": "I want to receive SMS about news, promotions and upcoming draws.",
		"error": {
			"auth/email-already-exists": "The email already exist",
			"auth/invalid-email": "Invalid email",
			"auth/operation-not-allowed": "Account creation not available",
			"auth/weak-password": "The password must have at least 6 charaters",
			"auth/user-disabled": "The user is disabled",
			"auth/user-not-found": "User not found",
			"auth/wrong-password": "The password is invalid.",
			"auth/too-many-requests": "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
			"auth/accepted-char": "Only letters, numbers and these special characters @$!%*?&()#+-_=  are accepted",
			"auth/invalid-password": "Invalid password. The password must have at least 6 charaters and only letters, numbers and these special characters @$!%*?&()#+-_=  are accepted",
			"fieldNotEmpty": "This field should not be empty",
			"emailRequired": "E-mail is required",
			"passwordRequired": "Password is required",
			"passwordDifferent": "Passwords does not match",
			"dobRequired": "Date of birth is required",
			"dobMinor": "You must have over 18 years old",
			"currentPassword": "Invalid current password",
			"unknown": "An error occurs, please verify your information of contact support."
		}
	},
	"registerPage": {
		"emailConfirmation": "A confirmation email was sent. Please confirm your email address. If you can not find the email, verify your spam folder.",
		"language": "Communication language",
		"currency": "Currency"
	},
	"actionPage": {
		"title": "Reset Your Password",
		"titleVerif": "Your email has been verified.",
		"home": "Go to home page",
		"submit": "Submit",
		"error": {
			"auth/expired-action-code": "Update password code expired",
			"auth/invalid-action-code": "Update password code invalid",
			"auth/user-disabled": "The user is disabled",
			"auth/user-not-found": "User not found",
			"auth/weak-password": "Wrong password"
		}
	},
	"editProfilePage": {
		"title": "Edit Profile Information",
		"firstName": "First Name",
		"lastName": "Last Name",
		"phoneNo": "Phone No",
		"language": "Language",
		"currency": "Currency"
	},
	"forgotPage": {
		"title": "Forgot Password ?",
		"subtitle": "No problem",
		"email": "Enter Your Email*",
		"retypeEmail": "Retype Your Email*",
		"submit": "Submit",
		"linkByEmail": "An email to reset your password was sent",
		"error": {
			"auth/invalid-email": "Invalid email",
			"auth/user-not-found": "No account associated to the email",
			"auth/argument-error": "Invalid email",
			"emailRequired": "E-mail is required",
			"undefined": "An error occurs"
		}
	},
	"faqPage": {
		"title": "Frequently Asked Question",
		"desc": "Get Instant answers for most common questions.",
		"noAnswerQuestion": "Did not get your answer here?",
		"noAnswerDesc": "If your are unable to get the answers then feel free to contact us by submit a support request. We will very happy to listen from you.",
		"noAnswerBtn": "Submit Request"
	},
	"contactPage": {
		"header": "Contact Us",
		"title": "Contact Info",
		"desc": "If you have any problems, suggestions or feedback then please feel free to contact us by email or direct with our team.",
		"chatTitle": "Chat with our team",
		"chatDesc": "Click on bottom left chat icon and start the conversation!",
		"writeToUs": "Write to Us",
		"name": "Name",
		"nameField": "Your Name",
		"email": "Email",
		"emailField": "Your Email",
		"message": "Message",
		"messageField": "Message",
		"sendBtn": "Send",
		"emailSent": "Message sent!",
		"orderInProcessWarning": " If you contacting us about a new order, note that your order is in progress and you will have your tickets when purchased by our agents.",
		"validation": {
			"emailRequired": "E-mail is required",
			"emailValid": "E-mail must be valid",
			"notEmpty": "This field should not be empty"
		}
	},
	"notifications": {
		"header": "New Notifications",
		"bannerMsg": {
			"winner": "It seems that you win something! Please check your message!",
			"genericMessage": "You got new message, click here to read it.",
		},
		"dialogMsg": {
			"win": "Congrationations, you win {amount}!!",
			"contact": "Please contact support, Lotto Amigo needs to talk to you.",
		}
	},
	"productsPage": {
		"addGrid": "Add Grid",
		"addToCart": "Add to Cart",
		"addAndCheckout": "Add and Checkout",
		"processOrder": "Add Order",
		"updateCart": "Update Cart",
		"combination": "Combinations",
		"singleTicket": "All combinations in a single ticket",
		"productAdded": "Ticket added to the cart",
		"grids": "Grids",
		"gridSelectedNone": "Select {selected} numbers",
		"gridSelectedAll": "All number selected",
		"gridSelected": "Select {selected_left} more numbers",
		"soldPrice": "Sold price",
		"useCredit": "Use Credit",
		"availableCredit": "Available Credit",
		"customerTicket": "Customer Ticket",
		"customer": "Customer",
		"customerId": "Id",
		"multiplier": {
			"megamillions": {
				"name": "Megaplier",
				"desc": "Multiply any non-jackpot prize amount won by 2, 3, 4 or 5, depending on the Megaplier number drawn."
			},
			"powerball": {
				"name": "Power Play",
				"desc": "The Power Play double the 2nd place prize to US$2,000,000, and multiply other prize categories by up to 10 times, depending on draw and jackpot size."
			}
		},
		"quickpicksNumbers": "Quickpicks numbers",
		"multiplaySingle": "Single Draw",
		"multiplayMultiple": "{num} Draws (save {save}% on fees)",
		"multiPlayDesc": "Play for multiple draws with the same ticket and save up to 25%!",
		"multiplayDraws": "Multiplay Draws",
		"multiplayDiscount": "Multiplay Discount",
		"singleDiscount": "Sprint Sale Discount",
		"onFees": " on fees",
		"numberPlay": "Number of draws",
		"selectionInfo": "Select Quickpicks or the grid mode.",
		"sections": {
			"quickPicks": {
				"title": "Quickpicks",
				"desc": "Save 1$ per combination using the Quickpicks. What means Quickpicks? Let the system choose the combination, you will know the numbers when you will receive the ticket. We save time, you save money!",
				"product": "combination"
			},
			"ownPicks": {
				"title": "Pick your own number",
				"info": "Minium of 3 pools"
			},
		},
		"error": {
			"missing_numbers": "Missing a number in one of your grid.",
			"nothing_to_add": "No product to add."
		},
		"gridsDisabled": "The selection grid is currently deactivated to allow us to respond to high demand.",
		"promotion": "PROMOTION",
		"iWantOffer": "I want this offer",
		"addedToCartDialog": "Your ticket has been added to your cart!",
		"continueShopping": "Continue Shopping",
		"goToCheckout": "Go to Checkout",
		"draws": "Draws",
		"quickpicks": "Quickpicks",

	},
	"freePage": {
		"header": "Free Lotteries",
		"subHeader": "Free lotteries? Yes! This unique concept allows you to subscribe to lottery groups for free. This is Lotto Amigo! You only need to click on the lottery ticket and subscribe to it. You will be automatically registered for that draw. Come again for every draw!",
		"subscribed": "Subscribed!",
		"noTicket": "No free ticket right now, come back tomorrow.",
		"enterHere": "Enter Here!"
	},
	"playFreePage": {
		"subscribeGroup": "Subscribe to this group",
		"subscribeGroupSmall": "(free)",
		"or": "or",
		"congratsAlready": "Congratulations, you already subscribed to this group!",
		"buyOwn": "Buy my own ticket",
		"congratsDialog": "Congratulations!",
		"buyOwnDialog": "You subscribed to the free lottery. Do you want to buy your own ticket?"
	},
	"myTicketsPage": {
		"noTicket": "You bought no ticket.",
		"noGiftTicket": "You did not received any gift ticket.",
		"noOrder": "You do not have any order in progress.",
		"ordersTab": "Orders",
		"ticketsTab": "Tickets",
		"giftTicketsTab": "Gifts",
		"thru": "thru",
		"winning": "Winning",
		"notWinning": "Not winning",
		"waitingResult": "Waiting for result",
		"cancelOrder": "Are you sure you want to cancel this order?",
		"orderInProcess": "You have an order in progress. It is normal that your ticket is not available yet, a team member will be sent out to make the purchase.",
		"orderInProcessLink": "Click here to see your order status",
		"orderInProcessInfo": "When your order will be ready, you will receive an email and your ticket will be in the Ticket section.",
		"seeMyTicket": "See my ticket",
		"more": "More",
		"replay": "Replay this ticket",
		"rebate": "rebate:",
		"replayDlgMessage": "Add this ticket to your cart?",
		"withMultiplier": {
			"powerball": " (with Power Play)",
			"megamillions": " (with Megaplier)"
		},
		"header": {
			"lotteryType": "Lotto Type",
			"numbers": "Numbers",
			"drawDate": "Draw Date",
			"receiptNumber": "Receipt Number",
			"price": "Price (USD)",
			"priceCurrency": "Paid Price",
			"status": "Status",
			"orderDate": "Order Date",
			"credit": "Credit",
			"offerBy": "Offer By",
			"coupon": "Coupon"
		},
		"statusDesc": {
			"sold_stripe": "Payment confirmed",
			"sold": "Money transfert verification",
			"assigned": "Buying order tickets",
			"scanned": "Processing your order",
			"completed": "Delivered",
			"canceled": "Canceled"
		}
	},
	"customerHistory": {
		"header": {
			"lotteryType": "Lotto Type",
			"numbers": "Numbers",
			"drawDate": "Draw Date",
			"receiptNumber": "Receipt Number",
			"price": "Price (USD)",
			"priceCurrency": "Paid Price",
			"status": "Status",
			"orderDate": "Order Date",
			"order_type": "Order Type",
			"credit": "Credit"
		},
		"seeDetails": "See Details"
	},
	"orderViewPage": {
		"orders": "Your Cart",
		"seeYouNumbers": "See your numbers",
		"edit": "Edit",
		"tax": "Tax(Florida sales tax {tax}%)",
		"noRefund": "I understand that no refund is possible. For more information, read the refund policies.",
		"interac_warning": "Only Interac payments in Canadian device (CAD) are accepted.",
		"deleteProduct": "Are you sure you want to delete this ticket?",
		"ticketRemoved": "Ticket removed from the cart",
		"unavailable": "Credit card payment is temporarily unavailable, you can use Interac!",
		"invalidCoupon": "Invalid coupon.",
		'gift': "Offer as a gift?",
		"giftDesc": "Enter the recipient's name and email. An message will be sent to him with his lucky numbers.<br /><br />* The recipient must have <b>18+</b>.",
		"giftName": "Name*",
		"giftEmail": "E-mail*",
		"giftNote": "Gift note",
		"groupDesc": "Enter your group name and it will be displayed on the ticket.<br /><br />* All group members must have <b>18+</b>.",
		"groupName": "Group Name*",
		"important": "Important",
		"transfertNote": "Make sure you are transferring the money using your email: {email}. Also, note that the beneficiary of the Interac transfer will be Thomas Samson.",
		"ccWarningTitle": "No Refunds",
		"ccWarningMsg": "The purchase of tickets is non-refundable; this is a final sale."

	},
	"billing": {
		"total": "Total",
		"subtotal": "Subtotal",
		"tax": "Tax({tax}%)",
		"checkout": "Checkout",
		"noProduct": "No Ticket",
		"fees": "fees",
		"paymentOptions": "Payment Options",
		"creditCard": "Card",
		"interac": "Interac Transfer",
		"useCredit": "Use your credit ({credit})",
		"credit": "Credit",
		"coupon": "Coupon",
		"enterCoupon": "Enter coupon here",
		"apply": "Apply",
		"couponLabel": "Coupon rebate of {rebate}% (on service)",
		"group": "Group",
		"pay": "Pay",
		"loading": "Loading...",
		"ticket_destination": {
			"title": "Ticket recipient",
			"forYou": "For you",
			"gift": "Give as a gift",
			"group": "For a group"
		}
	},
	"paymentPage": {
		"title": "Payment Information",
		"empty": "Your Shopping Bag is empty.",
		"goShopping": "Go to Shopping",
		"interacText1": "Confirm the order by clicking the button below. An order confirmation will be displayed and an email will be sent to you.<br/><br/>The information for the Interac transfer will be displayed on the confirmation page and the email.",
		"interacText2": "Last step: Click \"Order Confirmation\" to confirm your transaction.",
		"interacBtn": "Order Confirmation",
		"creditCardText1": "You have a credit of {credit}. This amount will be automatically deducted from your invoice.",
		"creditCardText2": "Click the button to pay with your credit card . Once your payment is processed you will receive a confirmation email.",
		"payNowBtn": "Pay Now!",
		"lastStep": "LAST STEP! Confirm the order by click here:",
		"notice": "<b>IMPORTANT:</b><br />Transfer email: <b>pay@serviceamigo.com</b><br />Question: <b>Lotto</b><br />Answer: <b>Amigo</b><br />",
		"promoCode": "Promo Code",
		"promoCodeDesc": "Enter yout promo code here",
		"promoCodeDesc2": "The promo code will be applied to your invoice when you pay.",
		"error": {
			"empty": "This field should not be empty.",
			"length": "The field must have 3 characters or more.",
			"send_order": "An error occurs, please try later.",
			"UNKNOWN": "An error occurs, contact support.",
			"INVALID_COUPON": "Invalid promo code.",
			"EXCEED_HOUR": "It's too late to buy {type_name} ticket. Delete it from yout cart or try tomorrow.",
			"invalid_gidt_info": "Invalid information for the gift",
			"GRIDS_DISABLED": "The selection of your own numbers is currently deactivated to allow us to respond to high demand. Please select only Quickpicks, sorry for the inconvenience.",
			"GRIDS_NEW_RULES": "If you choose your own numbers, you can only have 3 grids and a minimum of 5 draws.",
			"NO_AUTHORIZATION": "You are not authorized to buy this product.",
			"PAYMENT_ERROR": "An error occurred with the credit card payment. Please check your information or contact support.",
			"AMOUNT_LIMIT": "Your order amount exceeds the limit of {amount}$. Please adjust your order to proceed."
		},
		"interacEmailMsg": "To help us to process your order, please give us your email associated to your Interac account:",
		"interacEmailLabel": "Interac email here"
	},
	"finalReceiptPage": {
		"thankyou": "Thank You",
		"thanksMessage": "Your order has been received. An email will be sent to {email} when the payment will be confirm.",
		"paymentInfo": "Please, send your payment <mark>{amount}</mark> by following the payment instructions below:",
		"receiptNumber": "Receipt Number",
		"summary": "Summary",
		"orderId": "Order ID",
		"orderDate": "Order Date",
		"orderTotal": "Order Total",
		"orderDetails": "Your Ordered Details",
		"goToHome": "Go To Home",
		"verifyJunkmail": "* If you did not receive the email, check your junk mailbox *",
		"orderStatusDesc": "Follow your order status by going to \"My Tickets\" under \"Orders\" tab or by clicking here:",
		"goToOrders": "My Orders",
		"interacWarningTitle": "IMPORTANT",
		"interacWarningMsg": "New email address for the Interac payments:"
	},
	"accountPopup": {
		"yourCredit": "Your Credit:"
	},
	"profilePage": {
		"profileInfo": "Profile Information",
		"firstName": "First Name",
		"lastName": "Last Name",
		"dob": "Date Of Birth",
		"email": "E-mail",
		"phone": "Phone Number",
		"credit": "Credit",
		"orderHistory": "Order History",
		"userHistory": "User History",
		"actions": "Actions",
		"profile": "Profile",
		"language": "Communication language",
		"currency": "Currency",
		"changePassword": "Change Password",
		"changeEmail": "Change Email",
		"passwordChanged": "Your password has been changed.",
		"emailChanged": "Your email has been changed.",
		"currentPasswordLabel": "Current Password",
		"newPasswordLabel": "New Password",
		"newEmailLabel": "New Email",
		"cart": "Cart",
		"update": "Update"
	},
	"notificationsPage": {
		"noNotification": "No Notification"
	},
	"aboutPage": {
		"title": "About Us",
	},
	"termAndConditionPage": {
		"title": "Terms and Conditions",
		"subTitle": "Please read and contact if you have some issue.",
	},
	"partnerPage": {
		"header": "Partner",
		"promocode": "Promo Code",
		"referralStats": "Statistics",
		"yesterday": "Yesterday",
		"thisMonth": "This Month",
		"lastMonth": "Last Month",
		"total": "Total",
		"today": "Today"
	},
	"resultsPage": {
		"results": "Results",
		"date": "Date",
		"jackpot": "Jackpot",
		"won": "Won",
		"rollover": "Rollover",
		"multiplier": {
			"powerball": "Power Play {num}x",
			"megamillions": "Megaplier {num}x"
		}

	},
	"subscriptionsPage": {
		"label": "Subscriptions",
		"desc": "Subscriptions allow you to participate in the draws of your choice without having to come back to the site. You will be automatically registered for each draw.",
		"list": "My Subscriptions",
		"create": "Create Subscription",
		"update": "Update Subscription",
		"noSubscription": "You have no subscription yet. Create one now!",
		"moreInfo": "A subscription ensures you never miss a lottery draw. Choose to subscribe to all draws or only those with jackpots above a certain amount. Your subscription will automatically use the available credits in your account, so be sure to maintain enough balance. If your credits run low, we’ll notify you by email. You can unsubscribe at any time with no hassle.",
		"close": "Close",
		"frequencies": {
			"every": "Every Draw",
			"over": "When Jackpot is over {amount}M",
		},
		"priceInfo": "for every draws",
		"errors": {
			"QUICKPICKS_NOT_VALID": "Invalid number of Quick Picks.",
			"LOTTERY_TYPE_NOT_VALID": "Invalid lottery type, contact the support.",
			"SUBSCRIPTION_ALREADY_EXIST": "You already have a subscription with the same values.",
			"SUBSCRIPTION_NOT_FOUND": "Subscription not found.",
			"STATUS_NOT_VALID": "Invalid status, contact support",
		},
		"status": {
			"active": "Active",
			"paused": "Paused",
			"fund": "Insufficient funds (refill your account)",
			"deleted": "Deleted",
		},
	},
	"header": {
		"userProfile": "Your Profile",
		"notifications": "Notifications",
	},
	"banner": {
		"tooLate": "Too late for this draw, come back tomorrow!",
		"waitingResult": "Waiting for result, come back later.",
		"moreDetails": "Details",
		"aboutTitle": "LOTTO AMIGO",
		"aboutDesc": "Participate in the two biggest American lotteries without traveling using Lotto Amigo!",
		"freeTitle": "Free Lotteries",
		"freeDesc": "With Lotto Amigo you can participate to free lotterie groups!",
		"acceptCCDesc": "Now you can pay with your favorite payment method",
		"acceptCCTitle": "We accept all credit cards!",
		"nicole": "TEMPORARILY CLOSED: HURRICANE NICOLE",
		"showOffers": "Show Offers",
	},
	"emailVerified": {
		"bannerTitle": "Email not confirmed, please confirm your email address. If you don't see the email, it might be in your spam folder accidentally.",
		"bannerAction": "(Send again)",
		"verifiedNotif": "Email verified, thanks!",
		"emailSent": "Verification email sent."
	},
	"footer": {
		"aboutUsTitle": "ABOUT US",
		"aboutUsDesc": "Welcome to Lotto Amigo (together with its sub-domains, Content, Marks and Services, the “Site”). Lotto Amigo is an associated brand of BLACKDANES LLC, a company incorporated under the laws of Florida, with registration number 86-3829607"
	},
	"dialogs": {
		"areYourSure": "Are you sure you want to delete this product?",
		"productRemoving": "Product Removing from cart",
	},
	"session_warning": {
		"separate_ticket": "Orders containing over then 3 draws will be split in multiple tickets."
	},
	"promotionDialog": {
		"title": "Promotion",
	},
	"winningNumbers": "Winning Numbers",
	"date": "Date",
	"thankYou": "Thank You",
	"contactUs": "Contact Us",
	"social": "Social",
	"facebook": "Facebook",
	"twitter": "Twitter",
	"instagram": "Instagram",
	"youtube": "Youtube",
	"googlePlus": "Google Plus",
	"reports": "Reports",
	"orders": "Orders",
	"invoices": "Invoices",
	"profile": "Profile",
	"products": "Products",
	"productAdd": "Product Add",
	"goToSite": "Go To Site",
	"adminPanel": "Admin",
	"partnerPanel": "Partner",
	"edit": "Edit",
	"images": {
		"bannerNameTicket": "/static/images/slider-5-name-ticket.jpg",
		"blackFriday": "/static/images/blackfriday.jpg",
		"bannerPromo90": "/static/images/promo90_en.jpg",
		"bannerCC": "/static/images/slider-cc-en.jpeg",
	},
	"suspended":
		"Due to Hurricane Milton, sales are suspended until Friday, October 11 at 6am.",

}