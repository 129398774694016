<template>
  <div class="editInfo-wrap emb-card pa-4">
    <div class="">
      <h4>{{ $t("message.editProfilePage.title") }}</h4>
      <v-form ref="form" v-model="valid" class="mb-2">
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg6 xl6>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-text-field
                  :label="$t('message.editProfilePage.firstName')"
                  :rules="inputRules.basictextRules"
                  v-model="user_info['firstname']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-text-field
                  :label="$t('message.editProfilePage.lastName')"
                  :rules="inputRules.basictextRules"
                  v-model="user_info['lastname']"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  v-model="menu"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      v-on="on"
                      label="Birthday date"
                      append-icon="event"
                      readonly
                      :rules="inputRules.basictextRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="date"
                    min="1900-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-text-field
                  :label="$t('message.editProfilePage.phoneNo')"
                  :rules="inputRules.basictextRules"
                  v-model="user_info['phone']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-select
                  :items="languages"
                  item-text="name"
                  item-value="locale"
                  v-model="user_info['language']"
                  :label="$t('message.editProfilePage.language')"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-checkbox
                  color="accent"
                  v-model="sms"
                  :label="$t('message.signinPage.sms')"
                  ></v-checkbox>
              </v-flex>
              <!-- <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-select
                  :items="currencies"
                  item-text="title"
                  item-value="id"
                  v-model="user_info['currency']"
                  :label="$t('message.editProfilePage.currency')"
                ></v-select>
              </v-flex> -->
              <!-- <v-flex xs12 sm12 md12 lg12 xl12 py-1>
                <v-text-field
                  label="Email"
                  :rules="emailRules"
                  v-model="user_info['email']"
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 xl12 pt-1 pb-0>
                <v-btn
                  class="accent mx-0 mb-4"
                  @click.stop.prevent="saveDetails"
                  >Save</v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6 user-bg edit-profile hidden-md-and-down>
          </v-flex>
        </v-layout>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Timestamp } from "firebase/firestore";

export default {
  data() {
    return {
      val: "",
      valid: false,
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty"],
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      gender: "Male",
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      user_info: {},
      sms: true,
      old_sms: true,
      old_phone: "",
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "languages", "currencies"]),
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  beforeMount() {
    this.user_info = { ...this.getUserInfo };
    this.date = this.getUserInfo["dob"].toDate().toISOString().substr(0, 10);
    if (this.getUserInfo["sms"] != undefined) {
      this.sms = this.getUserInfo["sms"];
    }
    this.old_sms = this.sms;
    this.old_phone = this.getUserInfo["phone"];
  },
  methods: {
    saveDetails() {
      this.$refs.form.validate();
      if (this.valid == true) {
        this.user_info.dob = Timestamp.fromMillis(Date.parse(this.date));
        this.user_info.sms = this.sms;
        this.$store
          .dispatch("save_user_info", this.user_info)
          .then(() => {
            this.$snotify.success(
              "Your account Information Updated succesfully",
              {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 2000,
                showProgressBar: false,
              }
            );
          })
          .catch((error) => {
            console.log("ERROR!!: ", error);
            this.$snotify.error(
              "An error occurs, please contact the support.",
              {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
                showProgressBar: false,
              }
            );
          });

        setTimeout(() => {
          this.$router.push({ path: "/account/profile" });
        }, 50);
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>